@charset "UTF-8";
/* My styles */
@font-face {
  font-family: 'Montserrat';
  src: url("./../fonts/Montserrat-ThinItalic.eot");
  src: url("./../fonts/Montserrat-ThinItalic.eot?#iefix") format("embedded-opentype"), url("./../fonts/Montserrat-ThinItalic.woff2") format("woff2"), url("./../fonts/Montserrat-ThinItalic.woff") format("woff"), url("./../fonts/Montserrat-ThinItalic.ttf") format("truetype"), url("./../fonts/Montserrat-ThinItalic.svg#Montserrat-ThinItalic") format("svg");
  font-weight: 100;
  font-style: italic; }

@font-face {
  font-family: "lg";
  src: url("./../fonts/lg.woff") format("woff"), url("./../fonts/lg.ttf") format("ttf");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("./../fonts/Montserrat-SemiBold.eot");
  src: url("./../fonts/Montserrat-SemiBold.eot?#iefix") format("embedded-opentype"), url("./../fonts/Montserrat-SemiBold.woff2") format("woff2"), url("./../fonts/Montserrat-SemiBold.woff") format("woff"), url("./../fonts/Montserrat-SemiBold.ttf") format("truetype"), url("./../fonts/Montserrat-SemiBold.svg#Montserrat-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("./../fonts/Montserrat-Black.eot");
  src: url("./../fonts/Montserrat-Black.eot?#iefix") format("embedded-opentype"), url("./../fonts/Montserrat-Black.woff2") format("woff2"), url("./../fonts/Montserrat-Black.woff") format("woff"), url("./../fonts/Montserrat-Black.ttf") format("truetype"), url("./../fonts/Montserrat-Black.svg#Montserrat-Black") format("svg");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("./../fonts/Montserrat-ExtraBoldItalic.eot");
  src: url("./../fonts/Montserrat-ExtraBoldItalic.eot?#iefix") format("embedded-opentype"), url("./../fonts/Montserrat-ExtraBoldItalic.woff2") format("woff2"), url("./../fonts/Montserrat-ExtraBoldItalic.woff") format("woff"), url("./../fonts/Montserrat-ExtraBoldItalic.ttf") format("truetype"), url("./../fonts/Montserrat-ExtraBoldItalic.svg#Montserrat-ExtraBoldItalic") format("svg");
  font-weight: 800;
  font-style: italic; }

@font-face {
  font-family: 'Montserrat';
  src: url("./../fonts/Montserrat-Regular.eot");
  src: url("./../fonts/Montserrat-Regular.eot?#iefix") format("embedded-opentype"), url("./../fonts/Montserrat-Regular.woff2") format("woff2"), url("./../fonts/Montserrat-Regular.woff") format("woff"), url("./../fonts/Montserrat-Regular.ttf") format("truetype"), url("./../fonts/Montserrat-Regular.svg#Montserrat-Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("./../fonts/Montserrat-ExtraLight.eot");
  src: url("./../fonts/Montserrat-ExtraLight.eot?#iefix") format("embedded-opentype"), url("./../fonts/Montserrat-ExtraLight.woff2") format("woff2"), url("./../fonts/Montserrat-ExtraLight.woff") format("woff"), url("./../fonts/Montserrat-ExtraLight.ttf") format("truetype"), url("./../fonts/Montserrat-ExtraLight.svg#Montserrat-ExtraLight") format("svg");
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("./../fonts/Montserrat-ExtraBold.eot");
  src: url("./../fonts/Montserrat-ExtraBold.eot?#iefix") format("embedded-opentype"), url("./../fonts/Montserrat-ExtraBold.woff2") format("woff2"), url("./../fonts/Montserrat-ExtraBold.woff") format("woff"), url("./../fonts/Montserrat-ExtraBold.ttf") format("truetype"), url("./../fonts/Montserrat-ExtraBold.svg#Montserrat-ExtraBold") format("svg");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("./../fonts/Montserrat-Light.eot");
  src: url("./../fonts/Montserrat-Light.eot?#iefix") format("embedded-opentype"), url("./../fonts/Montserrat-Light.woff2") format("woff2"), url("./../fonts/Montserrat-Light.woff") format("woff"), url("./../fonts/Montserrat-Light.ttf") format("truetype"), url("./../fonts/Montserrat-Light.svg#Montserrat-Light") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("./../fonts/Montserrat-BlackItalic.eot");
  src: url("./../fonts/Montserrat-BlackItalic.eot?#iefix") format("embedded-opentype"), url("./../fonts/Montserrat-BlackItalic.woff2") format("woff2"), url("./../fonts/Montserrat-BlackItalic.woff") format("woff"), url("./../fonts/Montserrat-BlackItalic.ttf") format("truetype"), url("./../fonts/Montserrat-BlackItalic.svg#Montserrat-BlackItalic") format("svg");
  font-weight: 900;
  font-style: italic; }

@font-face {
  font-family: 'Montserrat';
  src: url("./../fonts/Montserrat-Medium.eot");
  src: url("./../fonts/Montserrat-Medium.eot?#iefix") format("embedded-opentype"), url("./../fonts/Montserrat-Medium.woff2") format("woff2"), url("./../fonts/Montserrat-Medium.woff") format("woff"), url("./../fonts/Montserrat-Medium.ttf") format("truetype"), url("./../fonts/Montserrat-Medium.svg#Montserrat-Medium") format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("./../fonts/Montserrat-BoldItalic.eot");
  src: url("./../fonts/Montserrat-BoldItalic.eot?#iefix") format("embedded-opentype"), url("./../fonts/Montserrat-BoldItalic.woff2") format("woff2"), url("./../fonts/Montserrat-BoldItalic.woff") format("woff"), url("./../fonts/Montserrat-BoldItalic.ttf") format("truetype"), url("./../fonts/Montserrat-BoldItalic.svg#Montserrat-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic; }

@font-face {
  font-family: 'Montserrat';
  src: url("./../fonts/Montserrat-ExtraLightItalic.eot");
  src: url("./../fonts/Montserrat-ExtraLightItalic.eot?#iefix") format("embedded-opentype"), url("./../fonts/Montserrat-ExtraLightItalic.woff2") format("woff2"), url("./../fonts/Montserrat-ExtraLightItalic.woff") format("woff"), url("./../fonts/Montserrat-ExtraLightItalic.ttf") format("truetype"), url("./../fonts/Montserrat-ExtraLightItalic.svg#Montserrat-ExtraLightItalic") format("svg");
  font-weight: 200;
  font-style: italic; }

@font-face {
  font-family: 'Montserrat';
  src: url("./../fonts/Montserrat-LightItalic.eot");
  src: url("./../fonts/Montserrat-LightItalic.eot?#iefix") format("embedded-opentype"), url("./../fonts/Montserrat-LightItalic.woff2") format("woff2"), url("./../fonts/Montserrat-LightItalic.woff") format("woff"), url("./../fonts/Montserrat-LightItalic.ttf") format("truetype"), url("./../fonts/Montserrat-LightItalic.svg#Montserrat-LightItalic") format("svg");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'Montserrat';
  src: url("./../fonts/Montserrat-SemiBoldItalic.eot");
  src: url("./../fonts/Montserrat-SemiBoldItalic.eot?#iefix") format("embedded-opentype"), url("./../fonts/Montserrat-SemiBoldItalic.woff2") format("woff2"), url("./../fonts/Montserrat-SemiBoldItalic.woff") format("woff"), url("./../fonts/Montserrat-SemiBoldItalic.ttf") format("truetype"), url("./../fonts/Montserrat-SemiBoldItalic.svg#Montserrat-SemiBoldItalic") format("svg");
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: 'Montserrat';
  src: url("./../fonts/Montserrat-Thin.eot");
  src: url("./../fonts/Montserrat-Thin.eot?#iefix") format("embedded-opentype"), url("./../fonts/Montserrat-Thin.woff2") format("woff2"), url("./../fonts/Montserrat-Thin.woff") format("woff"), url("./../fonts/Montserrat-Thin.ttf") format("truetype"), url("./../fonts/Montserrat-Thin.svg#Montserrat-Thin") format("svg");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("./../fonts/Montserrat-MediumItalic.eot");
  src: url("./../fonts/Montserrat-MediumItalic.eot?#iefix") format("embedded-opentype"), url("./../fonts/Montserrat-MediumItalic.woff2") format("woff2"), url("./../fonts/Montserrat-MediumItalic.woff") format("woff"), url("./../fonts/Montserrat-MediumItalic.ttf") format("truetype"), url("./../fonts/Montserrat-MediumItalic.svg#Montserrat-MediumItalic") format("svg");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: 'Montserrat';
  src: url("./../fonts/Montserrat-Italic.eot");
  src: url("./../fonts/Montserrat-Italic.eot?#iefix") format("embedded-opentype"), url("./../fonts/Montserrat-Italic.woff2") format("woff2"), url("./../fonts/Montserrat-Italic.woff") format("woff"), url("./../fonts/Montserrat-Italic.ttf") format("truetype"), url("./../fonts/Montserrat-Italic.svg#Montserrat-Italic") format("svg");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Montserrat';
  src: url("./../fonts/Montserrat-Bold.eot");
  src: url("./../fonts/Montserrat-Bold.eot?#iefix") format("embedded-opentype"), url("./../fonts/Montserrat-Bold.woff2") format("woff2"), url("./../fonts/Montserrat-Bold.woff") format("woff"), url("./../fonts/Montserrat-Bold.ttf") format("truetype"), url("./../fonts/Montserrat-Bold.svg#Montserrat-Bold") format("svg");
  font-weight: bold;
  font-style: normal; }

body {
  font-family: Montserrat, Arial, sans-serif;
  color: #333333;
  font-size: 14px;
  margin: 0;
  padding: 0; }

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

a {
  color: #333333;
  text-decoration: none; }
  a:hover {
    text-decoration: underline; }

input[type="text"],
input[type="tel"],
input[type="email"] {
  background: #FFFFFF;
  border: 1px solid #D7D6DA;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 3px;
  width: 340px;
  height: 50px;
  padding: 0 15px 4px; }

input[type="submit"] {
  -webkit-transition: 0.2s;
  transition: 0.2s;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  font-family: "Montserrat";
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  text-align: center;
  font-weight: 500;
  border-radius: 5px;
  position: relative;
  z-index: 2;
  overflow: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 14px;
  padding: 11px 20px;
  color: #fff;
  background: #457C00;
  border: 1px solid #457C00;
  border: 1px solid; }
  input[type="submit"]:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0px;
    right: 0;
    bottom: 0;
    z-index: -1;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    -webkit-transform: scaleY(0);
            transform: scaleY(0); }
  input[type="submit"]:hover:before {
    -webkit-transform: scale(2);
            transform: scale(2); }
  input[type="submit"]:before {
    background: #fff; }
  input[type="submit"]:hover {
    color: #457C00;
    border-color: #457C00; }
  input[type="submit"]:active {
    color: #294900;
    border-color: #294900;
    text-shadow: none; }
  input[type="submit"]:hover {
    background: #fff; }

textarea {
  background: #FFFFFF;
  border: 1px solid #D7D6DA;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 3px;
  width: 100%;
  resize: none;
  padding: 15px 15px;
  max-width: 1090px; }

.block-webform {
  max-width: 550px;
  background: #FFF;
  margin: auto;
  position: relative;
  -webkit-box-shadow: 0px 10px 75px rgba(24, 26, 61, 0.1);
          box-shadow: 0px 10px 75px rgba(24, 26, 61, 0.1);
  padding-bottom: 35px; }
  .block-webform .webform_title {
    padding: 33px 40px;
    font-weight: 600;
    line-height: 37px;
    font-size: 24px;
    color: #457C00;
    -webkit-box-shadow: 0px 10px 75px rgba(24, 26, 61, 0.1);
    box-shadow: 0px 10px 75px rgba(24, 26, 61, 0.1);
    background: #FFFFFF;
    box-shadow: 0px 10px 75px rgba(24, 26, 61, 0.1); }
  .block-webform .from_action {
    max-width: 340px;
    margin: auto; }
    .block-webform .from_action input[type="submit"] {
      width: 100%;
      font-size: 18px; }

.form-items {
  padding: 10px 0;
  max-width: 340px;
  margin: auto; }
  .form-items label {
    text-align: left; }

.form_label {
  padding-bottom: 10px;
  display: block; }

.reviews_item {
  padding: 10px 0; }

.webform_content {
  padding: 20px 40px; }

ul {
  margin: 0;
  padding: 0; }
  ul li {
    margin: 0;
    padding: 0; }

* {
  outline: none; }

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #000;
  opacity: 0.6; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
  .mfp-preloader a {
    color: #CCC; }
    .mfp-preloader a:hover {
      color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  -webkit-box-shadow: none;
          box-shadow: none;
  -ms-touch-action: manipulation;
      touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }
  .mfp-close:hover, .mfp-close:focus {
    opacity: 1; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -15px;
  text-align: center; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover, .mfp-arrow:focus {
    opacity: 1; }
  .mfp-arrow:before, .mfp-arrow:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
  .mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  .mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px; }
  .mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px; }
  .mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
  .mfp-iframe-holder .mfp-close {
    top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

.mfp-preloader {
  width: 40px;
  height: 40px;
  background-color: #FFF;
  margin: -20px auto 0;
  opacity: 0.8;
  -webkit-animation: rotateplane 1.2s infinite ease-in-out;
          animation: rotateplane 1.2s infinite ease-in-out; }

@-webkit-keyframes rotateplane {
  0% {
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
            transform: perspective(120px) rotateX(0deg) rotateY(0deg); }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
            transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg); }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
            transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg); } }

@keyframes rotateplane {
  0% {
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
            transform: perspective(120px) rotateX(0deg) rotateY(0deg); }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
            transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg); }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
            transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg); } }

.mfp-s-error .mfp-preloader {
  width: auto;
  height: auto;
  -webkit-animation: none;
          animation: none;
  background-color: transparent;
  opacity: 1; }

.mfp-ready .mfp-figure {
  opacity: 0; }

.mfp-zoom-in .mfp-with-anim,
.mfp-zoom-in .mfp-figure {
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: scale(0.7);
          transform: scale(0.7); }

.mfp-zoom-in .mfp-iframe-holder .mfp-content {
  opacity: 0;
  -webkit-transform: scale(0.9) translateY(-20px) perspective(600px) rotateX(10deg);
          transform: scale(0.9) translateY(-20px) perspective(600px) rotateX(10deg);
  -webkit-transition: all 0.5s;
  transition: all 0.5s; }

.mfp-zoom-in.mfp-bg,
.mfp-zoom-in .mfp-preloader {
  opacity: 0;
  -webkit-transition: all 0.4s;
  transition: all 0.4s; }

.mfp-zoom-in.mfp-image-loaded .mfp-figure {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1); }

.mfp-zoom-in.mfp-ready .mfp-with-anim {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1); }

.mfp-zoom-in.mfp-ready .mfp-iframe-holder .mfp-content {
  opacity: 1;
  -webkit-transform: scale(1) translateY(0) perspective(600px) rotateX(0);
          transform: scale(1) translateY(0) perspective(600px) rotateX(0); }

.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.6; }

.mfp-zoom-in.mfp-ready .mfp-preloader {
  opacity: 0.8; }

.mfp-zoom-in.mfp-removing .mfp-with-anim,
.mfp-zoom-in.mfp-removing .mfp-figure {
  -webkit-transform: scale(1.15);
          transform: scale(1.15);
  opacity: 0; }

.mfp-zoom-in.mfp-removing .mfp-iframe-holder .mfp-content {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  opacity: 0;
  -webkit-transition: all 0.4s;
  transition: all 0.4s; }

.mfp-zoom-in.mfp-removing.mfp-bg,
.mfp-zoom-in.mfp-removing .mfp-preloader {
  opacity: 0; }

.mfp-zoom-in-gallery .mfp-with-anim,
.mfp-zoom-in-gallery .mfp-figure,
.mfp-zoom-in-gallery .mfp-iframe-holder .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: scale(0.9);
          transform: scale(0.9); }

.mfp-zoom-in-gallery.mfp-bg,
.mfp-zoom-in-gallery .mfp-preloader {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; }

.mfp-zoom-in-gallery.mfp-image-loaded .mfp-figure {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1); }

.mfp-zoom-in-gallery.mfp-ready .mfp-with-anim,
.mfp-zoom-in-gallery.mfp-ready .mfp-iframe-holder .mfp-content {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1); }

.mfp-zoom-in-gallery.mfp-ready.mfp-bg {
  opacity: 0.6; }

.mfp-zoom-in-gallery.mfp-ready .mfp-preloader {
  opacity: 0.8; }

.mfp-zoom-in-gallery.mfp-removing .mfp-with-anim,
.mfp-zoom-in-gallery.mfp-removing .mfp-figure,
.mfp-zoom-in-gallery.mfp-removing .mfp-iframe-holder .mfp-content {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  opacity: 0; }

.mfp-zoom-in-gallery.mfp-removing.mfp-bg,
.mfp-zoom-in-gallery.mfp-removing .mfp-preloader {
  opacity: 0; }

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #000;
  opacity: 0.6; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
  .mfp-preloader a {
    color: #CCC; }
    .mfp-preloader a:hover {
      color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  -webkit-box-shadow: none;
          box-shadow: none;
  -ms-touch-action: manipulation;
      touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }
  .mfp-close:hover, .mfp-close:focus {
    opacity: 1; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -15px;
  text-align: center; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover, .mfp-arrow:focus {
    opacity: 1; }
  .mfp-arrow:before, .mfp-arrow:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
  .mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  .mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px; }
  .mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px; }
  .mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
  .mfp-iframe-holder .mfp-close {
    top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

.mfp-preloader {
  width: 40px;
  height: 40px;
  background-color: #FFF;
  margin: -20px auto 0;
  opacity: 0.8;
  -webkit-animation: rotateplane 1.2s infinite ease-in-out;
          animation: rotateplane 1.2s infinite ease-in-out; }

@keyframes rotateplane {
  0% {
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
            transform: perspective(120px) rotateX(0deg) rotateY(0deg); }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
            transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg); }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
            transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg); } }

.mfp-s-error .mfp-preloader {
  width: auto;
  height: auto;
  -webkit-animation: none;
          animation: none;
  background-color: transparent;
  opacity: 1; }

.mfp-ready .mfp-figure {
  opacity: 0; }

.mfp-zoom-in .mfp-with-anim,
.mfp-zoom-in .mfp-figure {
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: scale(0.7);
          transform: scale(0.7); }

.mfp-zoom-in .mfp-iframe-holder .mfp-content {
  opacity: 0;
  -webkit-transform: scale(0.9) translateY(-20px) perspective(600px) rotateX(10deg);
          transform: scale(0.9) translateY(-20px) perspective(600px) rotateX(10deg);
  -webkit-transition: all 0.5s;
  transition: all 0.5s; }

.mfp-zoom-in.mfp-bg,
.mfp-zoom-in .mfp-preloader {
  opacity: 0;
  -webkit-transition: all 0.4s;
  transition: all 0.4s; }

.mfp-zoom-in.mfp-image-loaded .mfp-figure {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1); }

.mfp-zoom-in.mfp-ready .mfp-with-anim {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1); }

.mfp-zoom-in.mfp-ready .mfp-iframe-holder .mfp-content {
  opacity: 1;
  -webkit-transform: scale(1) translateY(0) perspective(600px) rotateX(0);
          transform: scale(1) translateY(0) perspective(600px) rotateX(0); }

.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.6; }

.mfp-zoom-in.mfp-ready .mfp-preloader {
  opacity: 0.8; }

.mfp-zoom-in.mfp-removing .mfp-with-anim,
.mfp-zoom-in.mfp-removing .mfp-figure {
  -webkit-transform: scale(1.15);
          transform: scale(1.15);
  opacity: 0; }

.mfp-zoom-in.mfp-removing .mfp-iframe-holder .mfp-content {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  opacity: 0;
  -webkit-transition: all 0.4s;
  transition: all 0.4s; }

.mfp-zoom-in.mfp-removing.mfp-bg,
.mfp-zoom-in.mfp-removing .mfp-preloader {
  opacity: 0; }

.mfp-zoom-in-gallery .mfp-with-anim,
.mfp-zoom-in-gallery .mfp-figure,
.mfp-zoom-in-gallery .mfp-iframe-holder .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: scale(0.9);
          transform: scale(0.9); }

.mfp-zoom-in-gallery.mfp-bg,
.mfp-zoom-in-gallery .mfp-preloader {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; }

.mfp-zoom-in-gallery.mfp-image-loaded .mfp-figure {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1); }

.mfp-zoom-in-gallery.mfp-ready .mfp-with-anim,
.mfp-zoom-in-gallery.mfp-ready .mfp-iframe-holder .mfp-content {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1); }

.mfp-zoom-in-gallery.mfp-ready.mfp-bg {
  opacity: 0.6; }

.mfp-zoom-in-gallery.mfp-ready .mfp-preloader {
  opacity: 0.8; }

.mfp-zoom-in-gallery.mfp-removing .mfp-with-anim,
.mfp-zoom-in-gallery.mfp-removing .mfp-figure,
.mfp-zoom-in-gallery.mfp-removing .mfp-iframe-holder .mfp-content {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  opacity: 0; }

.mfp-zoom-in-gallery.mfp-removing.mfp-bg,
.mfp-zoom-in-gallery.mfp-removing .mfp-preloader {
  opacity: 0; }

.container {
  max-width: 1800px;
  width: 100%;
  margin: 0 auto; }

.middle {
  overflow: hidden; }

.header_wrapper .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative;
  height: 45px; }

.header_wrapper .header_top {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(48.89%, rgba(0, 0, 0, 0)), color-stop(207.78%, rgba(0, 0, 0, 0.14))), #F8F8FC;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 48.89%, rgba(0, 0, 0, 0.14) 207.78%), #F8F8FC; }
  .header_wrapper .header_top .logo {
    position: absolute;
    width: 310px;
    left: 50%;
    margin-left: -155px;
    top: 0;
    background: linear-gradient(170.89deg, #FFFFFF -0.96%, rgba(243, 243, 251, 0.15) 88.34%), #FFFFFF;
    -webkit-box-shadow: 0px 4px 45px rgba(42, 45, 60, 0.1);
            box-shadow: 0px 4px 45px rgba(42, 45, 60, 0.1);
    padding: 20px 0;
    text-align: center; }
    .header_wrapper .header_top .logo a {
      position: relative;
      z-index: 2;
      display: block; }
  .header_wrapper .header_top .address {
    padding: 11px 0; }
  .header_wrapper .header_top .phone_header {
    padding: 11px 0;
    padding-left: 37px; }
  .header_wrapper .header_top .sign_up_btn {
    -webkit-transition: 0.2s;
    transition: 0.2s;
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    font-family: "Montserrat";
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    text-align: center;
    font-weight: 500;
    border-radius: 5px;
    position: relative;
    z-index: 2;
    overflow: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    font-size: 14px;
    padding: 11px 20px;
    color: #fff;
    background: #457C00;
    border: 1px solid #457C00;
    padding: 0 12px;
    line-height: 45px;
    border-radius: 0; }
    .header_wrapper .header_top .sign_up_btn:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0px;
      right: 0;
      bottom: 0;
      z-index: -1;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      -webkit-transform: scaleY(0);
              transform: scaleY(0); }
    .header_wrapper .header_top .sign_up_btn:hover:before {
      -webkit-transform: scale(2);
              transform: scale(2); }
    .header_wrapper .header_top .sign_up_btn:before {
      background: #fff; }
    .header_wrapper .header_top .sign_up_btn:hover {
      color: #457C00;
      border-color: #457C00; }
    .header_wrapper .header_top .sign_up_btn:active {
      color: #294900;
      border-color: #294900;
      text-shadow: none; }

.header_wrapper .left_side {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.header_wrapper .right_side {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.header_wrapper .search_block_wrap {
  position: relative; }
  .header_wrapper .search_block_wrap .search_btn {
    width: 24px;
    height: 100%;
    background: url(../images/search-ico.svg) no-repeat center;
    cursor: pointer;
    margin-right: 22px; }
    .header_wrapper .search_block_wrap .search_btn:hover {
      opacity: 0.7; }
  .header_wrapper .search_block_wrap .search_form {
    position: fixed;
    display: none;
    z-index: 100;
    left: 50%;
    margin-left: -227px;
    top: 50%;
    margin-top: -25px; }
  .header_wrapper .search_block_wrap .from_action {
    padding-left: 15px; }

.header_wrapper .header_bottom .manin_menu {
  max-width: 1800px;
  width: 100%;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative; }
  .header_wrapper .header_bottom .manin_menu .first_menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 660px;
    width: 100%;
    height: 95px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .header_wrapper .header_bottom .manin_menu .first_menu .menu_list {
      vertical-align: middle;
      margin: 0 34px;
      list-style: none; }
      .header_wrapper .header_bottom .manin_menu .first_menu .menu_list .main_menu_link {
        display: inline-block;
        padding: 8px 21px 8px 21px;
        font-weight: 500;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        position: relative;
        z-index: 6; }
        .header_wrapper .header_bottom .manin_menu .first_menu .menu_list .main_menu_link:hover, .header_wrapper .header_bottom .manin_menu .first_menu .menu_list .main_menu_link.active {
          background: #fff;
          color: #333;
          -webkit-transition: 0.3s;
          transition: 0.3s;
          text-decoration: none; }
      .header_wrapper .header_bottom .manin_menu .first_menu .menu_list:hover .main_menu_link {
        background: #fff;
        color: #333;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        text-decoration: none; }
      .header_wrapper .header_bottom .manin_menu .first_menu .menu_list.expanded .main_menu_link {
        display: inline-block;
        padding: 8px 48px 8px 21px;
        font-weight: 500;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        position: relative; }
        .header_wrapper .header_bottom .manin_menu .first_menu .menu_list.expanded .main_menu_link::after {
          content: '';
          background: url(../images/chevorn-down.svg) no-repeat center;
          width: 12px;
          height: 12px;
          position: absolute;
          display: block;
          right: 15px;
          top: 50%;
          margin-top: -6px; }
        .header_wrapper .header_bottom .manin_menu .first_menu .menu_list.expanded .main_menu_link:hover::after, .header_wrapper .header_bottom .manin_menu .first_menu .menu_list.expanded .main_menu_link.active::after {
          background: url(../images/chevron-down-white.svg) no-repeat center; }
  .header_wrapper .header_bottom .manin_menu .right_menu {
    text-align: right; }
    .header_wrapper .header_bottom .manin_menu .right_menu .second_menu {
      text-align: left; }

/*header_wrapper*/
.footer {
  height: 176px;
  background: #ffffff; }
  .footer .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
  .footer .footer_top {
    height: 132px; }
  .footer .logo_footer {
    padding: 34px 0;
    text-align: center; }
  .footer .footer_menu {
    display: table;
    max-width: 720px;
    width: 100%;
    height: 100%; }
    .footer .footer_menu li {
      display: table-cell;
      vertical-align: middle;
      padding-right: 42px; }
      .footer .footer_menu li a {
        font-size: 12px; }
        .footer .footer_menu li a.active {
          text-decoration: underline; }
      .footer .footer_menu li.expanded a {
        padding-right: 10px;
        position: relative; }
        .footer .footer_menu li.expanded a::after {
          content: '';
          background: url(../images/min-chevron.svg) no-repeat center;
          width: 8px;
          height: 8px;
          position: absolute;
          display: block;
          right: -5px;
          top: 50%;
          margin-top: -4px; }
      .footer .footer_menu li:last-child {
        padding-right: 0; }
  .footer .footer_bottom {
    background: -webkit-gradient(linear, left bottom, left top, color-stop(48.89%, rgba(0, 0, 0, 0)), color-stop(207.78%, rgba(0, 0, 0, 0.14))), #F8F8FC;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 48.89%, rgba(0, 0, 0, 0.14) 207.78%), #F8F8FC;
    height: 44px; }
  .footer .group_wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .footer .copyright {
    padding: 13px 0; }
  .footer .sign_up_btn {
    -webkit-transition: 0.2s;
    transition: 0.2s;
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    font-family: "Montserrat";
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    text-align: center;
    font-weight: 500;
    border-radius: 5px;
    position: relative;
    z-index: 2;
    overflow: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    font-size: 14px;
    padding: 11px 20px;
    color: #fff;
    background: #457C00;
    border: 1px solid #457C00;
    padding: 0 12px;
    line-height: 44px;
    margin-left: 25px;
    border-radius: 0; }
    .footer .sign_up_btn:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0px;
      right: 0;
      bottom: 0;
      z-index: -1;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      -webkit-transform: scaleY(0);
              transform: scaleY(0); }
    .footer .sign_up_btn:hover:before {
      -webkit-transform: scale(2);
              transform: scale(2); }
    .footer .sign_up_btn:before {
      background: #fff; }
    .footer .sign_up_btn:hover {
      color: #457C00;
      border-color: #457C00; }
    .footer .sign_up_btn:active {
      color: #294900;
      border-color: #294900;
      text-shadow: none; }
  .footer .address {
    padding: 13px 0; }
  .footer .phone_header {
    padding: 13px 0;
    padding-left: 25px; }

/*footer*/
.middle .slider_front_wrapper {
  position: relative;
  height: 784px; }
  .middle .slider_front_wrapper .slider_front {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; }
  .middle .slider_front_wrapper .slide {
    height: 784px;
    position: relative; }
    .middle .slider_front_wrapper .slide::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(125.81deg, rgba(0, 0, 0, 0) 46%, rgba(0, 0, 0, 0.16) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)); }
  .middle .slider_front_wrapper .slick-arrow {
    position: absolute;
    width: 42px;
    height: 42px;
    background: url(../images/slider-arrow.svg) no-repeat center;
    border: none;
    z-index: 2;
    font-size: 0;
    cursor: pointer;
    top: 50%;
    margin-top: -21px;
    left: 50%;
    margin-left: -585px; }
    .middle .slider_front_wrapper .slick-arrow.slick-next {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      left: auto;
      margin-left: 0;
      right: 50%;
      margin-right: -585px; }
    .middle .slider_front_wrapper .slick-arrow:hover {
      opacity: 0.7; }

.middle .slick-dots {
  position: absolute;
  bottom: 37px;
  left: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 2; }
  .middle .slick-dots li {
    list-style: none;
    margin: 0 7px; }
    .middle .slick-dots li button {
      width: 14px;
      height: 14px;
      border: none;
      font-size: 0;
      background: #FFFFFF;
      border-radius: 50%;
      cursor: pointer; }
    .middle .slick-dots li.slick-active button {
      background: #457C00; }

.middle .slider_content_wrapper {
  position: relative;
  z-index: 1;
  height: 100%; }
  .middle .slider_content_wrapper .container {
    padding-top: 275px;
    height: 100%;
    position: relative; }
  .middle .slider_content_wrapper .title {
    max-width: 1030px;
    width: 100%;
    margin: 0 auto;
    font-weight: 600;
    line-height: 55px;
    font-size: 36px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF; }
  .middle .slider_content_wrapper .button_wrap {
    max-width: 690px;
    width: 100%;
    margin: 0 auto;
    padding-top: 38px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
  .middle .slider_content_wrapper .services_btn a,
  .middle .slider_content_wrapper .doctor_btn a {
    background: #ffffff;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    color: #457C00;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    border-radius: 5px;
    line-height: 50px;
    width: 220px;
    display: block; }
    .middle .slider_content_wrapper .services_btn a:hover,
    .middle .slider_content_wrapper .doctor_btn a:hover {
      background: #457C00;
      color: #ffffff;
      -webkit-transition: 0.3;
      transition: 0.3;
      cursor: pointer;
      text-decoration: none; }
  .middle .slider_content_wrapper .order_call_btn {
    background: #ffffff;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    color: #457C00;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    border-radius: 5px;
    line-height: 50px;
    width: 220px;
    display: block; }
    .middle .slider_content_wrapper .order_call_btn:hover {
      background: #457C00;
      color: #ffffff;
      -webkit-transition: 0.3;
      transition: 0.3;
      cursor: pointer;
      text-decoration: none; }
  .middle .slider_content_wrapper .text_after {
    max-width: 260px;
    width: 100%;
    margin: 0 auto;
    padding-top: 38px; }
    .middle .slider_content_wrapper .text_after .first_text {
      font-weight: 600;
      line-height: 55px;
      font-size: 36px;
      text-align: center;
      color: #FFFFFF; }
    .middle .slider_content_wrapper .text_after .second_text {
      line-height: 27px;
      font-size: 18px;
      text-align: right;
      color: #FFFFFF; }
  .middle .slider_content_wrapper .iso_wrapper {
    position: absolute;
    bottom: 0;
    right: 0; }
    .middle .slider_content_wrapper .iso_wrapper .iso_img img {
      display: block; }
    .middle .slider_content_wrapper .iso_wrapper .iso_description {
      display: none;
      position: absolute;
      bottom: 105px;
      right: 0;
      background: #FFFFFF;
      width: 296px;
      border-radius: 5px;
      padding: 8px 15px 8px 21px;
      font-weight: 600;
      line-height: 21px;
      font-size: 14px; }
      .middle .slider_content_wrapper .iso_wrapper .iso_description::after {
        content: '';
        width: 14px;
        height: 14px;
        background: #FFFFFF;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
        position: absolute;
        bottom: -5px;
        right: 30px;
        z-index: -1; }
    .middle .slider_content_wrapper .iso_wrapper:hover {
      cursor: pointer; }
      .middle .slider_content_wrapper .iso_wrapper:hover .iso_description {
        display: block; }

.middle .our_services {
  padding-top: 50px; }
  .middle .our_services .block_title {
    font-weight: 800;
    line-height: 55px;
    font-size: 36px;
    text-align: center;
    padding-bottom: 20px; }
  .middle .our_services .services_content {
    padding-top: 37px;
    font-size: 0; }
  .middle .our_services .service {
    width: 25%;
    display: inline-block;
    vertical-align: top;
    min-height: 290px;
    position: relative;
    -webkit-transition: 0.3s;
    transition: 0.3s; }
    .middle .our_services .service img {
      width: 100%;
      display: block; }
    .middle .our_services .service .link {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 80px;
      -webkit-transition: 0.3s;
      transition: 0.3s; }
      .middle .our_services .service .link a {
        background: linear-gradient(251.83deg, rgba(255, 255, 255, 0.06) 3.13%, rgba(255, 255, 255, 0) 52.76%), #50840F;
        color: #FFFFFF;
        font-weight: bold;
        font-size: 16px;
        text-align: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        text-decoration: none;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        line-height: 80px;
        height: 80px;
        -webkit-transition: 0.3s;
        transition: 0.3s; }
    .middle .our_services .service .row_wrapper {
      -webkit-transition: 0.3s;
      transition: 0.3s;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1; }
      .middle .our_services .service .row_wrapper::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: -webkit-gradient(linear, left top, right top, color-stop(-14.12%, rgba(0, 0, 0, 0.2)), color-stop(77.72%, rgba(0, 0, 0, 0)));
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.2) -14.12%, rgba(0, 0, 0, 0) 77.72%); }
    .middle .our_services .service:hover {
      -webkit-transition: 0.3s;
      transition: 0.3s; }
      .middle .our_services .service:hover .row_wrapper {
        position: absolute;
        top: -23px;
        right: -35px;
        bottom: -23px;
        left: -35px;
        z-index: 2; }
      .middle .our_services .service:hover .link {
        height: 100%;
        -webkit-transition: 0.3s;
        transition: 0.3s; }
        .middle .our_services .service:hover .link a {
          height: 100%;
          line-height: normal;
          background: transparent;
          -webkit-transition: 0.3s;
          transition: 0.3s; }

/*middle*/
.our_specialists {
  background: url(../images/bg-specialists-block.jpg);
  padding-top: 30px;
  padding-bottom: 70px; }

.block_title {
  text-align: center;
  font-weight: 800;
  line-height: 55px;
  font-size: 36px;
  padding-bottom: 20px; }

.specialists_content {
  max-width: 1656px;
  margin: auto;
  font-size: 0;
  -webkit-box-shadow: 0px 25px 75px rgba(81, 107, 115, 0.15);
          box-shadow: 0px 25px 75px rgba(81, 107, 115, 0.15); }
  .specialists_content .specialists {
    display: inline-block;
    width: 25%;
    font-size: 14px;
    text-align: center;
    vertical-align: top;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(29.26%, rgba(255, 255, 255, 0.25)), color-stop(102.22%, rgba(247, 248, 251, 0.25))), #FFFFFF;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 29.26%, rgba(247, 248, 251, 0.25) 102.22%), #FFFFFF;
    min-height: 190px;
    border-right: 1px solid #F0F0F6;
    border-bottom: 1px solid #F0F0F6;
    position: relative; }
    .specialists_content .specialists .title_link a {
      text-decoration: none;
      font-weight: 600; }
    .specialists_content .specialists:hover .row_wrapper {
      left: -15px;
      top: -15px;
      bottom: -15px;
      right: -15px;
      background: -webkit-gradient(linear, left top, left bottom, color-stop(29.26%, rgba(255, 255, 255, 0.25)), color-stop(102.22%, rgba(247, 248, 251, 0.25))), #FFFFFF;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 29.26%, rgba(247, 248, 251, 0.25) 102.22%), #FFFFFF;
      -webkit-box-shadow: 0px 15px 45px rgba(0, 0, 0, 0.1);
              box-shadow: 0px 15px 45px rgba(0, 0, 0, 0.1);
      z-index: 2;
      -webkit-transition: 0.2s;
      transition: 0.2s; }
    .specialists_content .specialists:hover .description {
      max-height: 999px;
      -webkit-transition: max-height 0.7s;
      transition: max-height 0.7s; }
    .specialists_content .specialists:hover .title_link {
      padding-top: 0; }
      .specialists_content .specialists:hover .title_link a {
        color: #457C00; }
  .specialists_content .icon_wrap {
    height: 70px; }
  .specialists_content .description {
    max-height: 0;
    overflow: hidden;
    font-weight: 500;
    line-height: 18px;
    font-size: 12px;
    padding: 0px 77px; }
  .specialists_content .row_wrapper {
    padding-top: 37px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    -webkit-transition: 0.2s;
    transition: 0.2s; }
  .specialists_content .title_link {
    width: 270px;
    padding: 10px 0;
    margin: auto; }
    .specialists_content .title_link a {
      font-weight: 600;
      line-height: 21px;
      font-size: 14px;
      text-align: center; }

.our_advantages {
  padding: 30px 0; }

.advantages_content {
  max-width: 1800px;
  margin: auto;
  font-size: 0;
  -webkit-box-shadow: 0px 25px 75px rgba(81, 107, 115, 0.15);
          box-shadow: 0px 25px 75px rgba(81, 107, 115, 0.15);
  padding-top: 30px; }
  .advantages_content .advantages {
    display: inline-block;
    width: 25%;
    font-size: 14px;
    text-align: center;
    vertical-align: top;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(29.26%, rgba(255, 255, 255, 0.25)), color-stop(102.22%, rgba(247, 248, 251, 0.25))), #FFFFFF;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 29.26%, rgba(247, 248, 251, 0.25) 102.22%), #FFFFFF;
    min-height: 250px;
    border-right: 1px solid #F0F0F6;
    position: relative; }
    .advantages_content .advantages:hover .row_wrapper {
      left: -15px;
      top: -15px;
      bottom: -15px;
      right: -15px;
      background: -webkit-gradient(linear, left top, left bottom, color-stop(29.26%, rgba(255, 255, 255, 0.25)), color-stop(102.22%, rgba(247, 248, 251, 0.25))), #FFFFFF;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 29.26%, rgba(247, 248, 251, 0.25) 102.22%), #FFFFFF;
      -webkit-box-shadow: 0px 15px 45px rgba(0, 0, 0, 0.1);
              box-shadow: 0px 15px 45px rgba(0, 0, 0, 0.1);
      z-index: 2;
      -webkit-transition: 0.2s;
      transition: 0.2s; }
    .advantages_content .advantages:hover .button_block {
      display: block; }
  .advantages_content .icon_wrap {
    height: 70px; }
  .advantages_content .description {
    font-weight: 500;
    line-height: 18px;
    font-size: 12px;
    max-width: 360px;
    margin: auto;
    padding-bottom: 12px; }
  .advantages_content .button_block {
    display: none; }
    .advantages_content .button_block a {
      -webkit-transition: 0.2s;
      transition: 0.2s;
      display: inline-block;
      cursor: pointer;
      text-decoration: none;
      font-family: "Montserrat";
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
      text-align: center;
      font-weight: 500;
      border-radius: 5px;
      position: relative;
      z-index: 2;
      overflow: hidden;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      font-size: 14px;
      padding: 11px 20px;
      color: #fff;
      background: #457C00;
      border: 1px solid #457C00; }
      .advantages_content .button_block a:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0px;
        right: 0;
        bottom: 0;
        z-index: -1;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        -webkit-transform: scaleY(0);
                transform: scaleY(0); }
      .advantages_content .button_block a:hover:before {
        -webkit-transform: scale(2);
                transform: scale(2); }
      .advantages_content .button_block a:before {
        background: #fff; }
      .advantages_content .button_block a:hover {
        color: #457C00;
        border-color: #457C00; }
      .advantages_content .button_block a:active {
        color: #294900;
        border-color: #294900;
        text-shadow: none; }
  .advantages_content .row_wrapper {
    padding-top: 37px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    -webkit-transition: 0.2s;
    transition: 0.2s; }
  .advantages_content .title_link {
    width: 270px;
    padding: 10px 0;
    margin: auto; }
    .advantages_content .title_link a {
      font-weight: 600;
      line-height: 21px;
      font-size: 14px;
      text-align: center; }

.block_news {
  background: url(../images/bg-specialists-block.jpg);
  padding-top: 30px;
  padding-bottom: 70px; }
  .block_news .conteiner {
    max-width: 1800px;
    margin: auto; }
  .block_news .block_more {
    text-align: center;
    padding-top: 40px; }
    .block_news .block_more .more_linc {
      -webkit-transition: 0.2s;
      transition: 0.2s;
      display: inline-block;
      cursor: pointer;
      text-decoration: none;
      font-family: "Montserrat";
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
      text-align: center;
      font-weight: 500;
      border-radius: 5px;
      position: relative;
      z-index: 2;
      overflow: hidden;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      font-size: 14px;
      padding: 11px 20px;
      color: #fff;
      background: #457C00;
      border: 1px solid #457C00; }
      .block_news .block_more .more_linc:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0px;
        right: 0;
        bottom: 0;
        z-index: -1;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        -webkit-transform: scaleY(0);
                transform: scaleY(0); }
      .block_news .block_more .more_linc:hover:before {
        -webkit-transform: scale(2);
                transform: scale(2); }
      .block_news .block_more .more_linc:before {
        background: #fff; }
      .block_news .block_more .more_linc:hover {
        color: #457C00;
        border-color: #457C00; }
      .block_news .block_more .more_linc:active {
        color: #294900;
        border-color: #294900;
        text-shadow: none; }

.news_container {
  margin: 0 -15px;
  font-size: 0; }
  .news_container .news_block {
    display: inline-block;
    vertical-align: top;
    width: 33.3%;
    padding: 0 15px;
    font-size: 14px; }
    .news_container .news_block .img_wrap {
      border-radius: 5px; }
    .news_container .news_block .date_wrap {
      padding: 15px 0;
      line-height: 18px;
      font-size: 12px; }
    .news_container .news_block .desc_wrap {
      line-height: 27px;
      font-size: 18px; }

.block_check_yourself {
  text-align: center;
  background: url(../images/bg-check_yourself.png) no-repeat center center;
  background-size: cover;
  padding-top: 70px;
  padding-bottom: 85px; }
  .block_check_yourself .title_wrpa {
    font-weight: 800;
    line-height: 55px;
    font-size: 36px;
    text-align: center;
    text-transform: uppercase;
    padding: 20px 0;
    color: #FFFFFF; }
  .block_check_yourself .desc_wrap {
    text-align: center;
    font-weight: 500;
    color: #FFFFFF;
    padding-bottom: 25px; }
  .block_check_yourself .btn_check {
    -webkit-transition: 0.2s;
    transition: 0.2s;
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    font-family: "Montserrat";
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    text-align: center;
    font-weight: 500;
    border-radius: 5px;
    position: relative;
    z-index: 2;
    overflow: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    font-size: 14px;
    padding: 11px 20px;
    background: #fff;
    color: #457C00;
    border: 1px solid #457C00;
    border: 1px solid #fff; }
    .block_check_yourself .btn_check:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0px;
      right: 0;
      bottom: 0;
      z-index: -1;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      -webkit-transform: scaleY(0);
              transform: scaleY(0); }
    .block_check_yourself .btn_check:hover:before {
      -webkit-transform: scale(2);
              transform: scale(2); }
    .block_check_yourself .btn_check:before {
      background: #457C00; }
    .block_check_yourself .btn_check:hover {
      color: #fff; }
    .block_check_yourself .btn_check:active {
      color: #294900;
      border-color: #294900; }

.block_reviews {
  background: url(../images/bg-specialists-block.jpg);
  padding-top: 30px;
  padding-bottom: 70px; }
  .block_reviews .conteiner {
    max-width: 1800px;
    margin: auto; }
  .block_reviews .reviews_conteiner {
    font-size: 0;
    margin-top: 35px;
    -webkit-box-shadow: 0px 25px 75px rgba(81, 107, 115, 0.15);
            box-shadow: 0px 25px 75px rgba(81, 107, 115, 0.15); }
  .block_reviews .reviews_row {
    font-size: 14px;
    display: inline-block;
    vertical-align: top;
    width: 33.33%;
    text-align: center;
    border-left: 1px solid #F0F0F6;
    min-height: 325px;
    position: relative; }
    .block_reviews .reviews_row .wrap_row {
      background: #ffffff;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-transition: 0.2s;
      transition: 0.2s; }
      .block_reviews .reviews_row .wrap_row .link_reviews a {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        font-size: 0; }
      .block_reviews .reviews_row .wrap_row .field_title {
        font-weight: 600;
        line-height: 24px;
        font-size: 16px;
        text-align: center;
        padding-top: 66px; }
    .block_reviews .reviews_row:hover .wrap_row {
      top: -15px;
      left: -15px;
      bottom: -15px;
      right: -15px;
      z-index: 1;
      -webkit-box-shadow: 0px 15px 45px rgba(0, 0, 0, 0.1);
              box-shadow: 0px 15px 45px rgba(0, 0, 0, 0.1); }

.top_line_block {
  background: url(../images/bg-bredc.png) no-repeat center center;
  background-size: cover; }

.line_wrapper {
  max-width: 1800px;
  margin: auto;
  padding: 20px 0; }
  .line_wrapper .page_title {
    font-weight: 700;
    line-height: 55px;
    font-size: 36px;
    /* White */
    color: #FFFFFF; }
  .line_wrapper .main_breadcrumba {
    font-weight: 500;
    line-height: 18px;
    font-size: 12px;
    color: #FFFFFF; }
    .line_wrapper .main_breadcrumba a {
      font-weight: 500;
      line-height: 18px;
      font-size: 12px;
      color: #FFFFFF; }

/*Doctors*/
.block-system-main {
  min-height: calc(100vh - 142px);
  max-width: 1800px;
  margin: auto;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 10px 75px rgba(24, 26, 61, 0.1);
          box-shadow: 0px 10px 75px rgba(24, 26, 61, 0.1);
  margin-bottom: 30px; }

.header_contenc_page {
  padding: 33px 40px;
  font-weight: 600;
  line-height: 37px;
  font-size: 24px;
  color: #457C00;
  -webkit-box-shadow: 0px 10px 75px rgba(24, 26, 61, 0.1);
          box-shadow: 0px 10px 75px rgba(24, 26, 61, 0.1);
  background: #FFFFFF;
  box-shadow: 0px 10px 75px rgba(24, 26, 61, 0.1); }

.doctors_conteiner {
  margin: 25px;
  font-size: 0;
  padding-bottom: 25px; }
  .doctors_conteiner .doctors_row {
    display: inline-block;
    vertical-align: top;
    width: 20%;
    padding: 15px;
    font-size: 14px;
    text-align: center; }
    .doctors_conteiner .doctors_row .row_wrap {
      position: relative;
      background: -webkit-gradient(linear, left top, left bottom, from(#FFFFFF), color-stop(108.25%, #F5F6F9)), #FFFFFF;
      background: linear-gradient(180deg, #FFFFFF 0%, #F5F6F9 108.25%), #FFFFFF;
      border: 1px solid #DFDEF1;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      border-radius: 5px;
      overflow: hidden; }
    .doctors_conteiner .doctors_row .link_wraper a {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      font-size: 0; }
    .doctors_conteiner .doctors_row .row_title {
      font-weight: 500;
      line-height: 21px;
      padding: 25px 23px;
      -webkit-transition: 0.2s;
      transition: 0.2s; }
    .doctors_conteiner .doctors_row:hover .row_title {
      color: #457C00; }

.doctors-list-block {
  margin: 0 15px;
  font-size: 0;
  padding-top: 25px; }
  .doctors-list-block .doctors-list-row {
    display: inline-block;
    width: 20%;
    vertical-align: top;
    padding: 15px;
    font-size: 14px; }
  .doctors-list-block .field_title {
    font-size: 18px;
    padding: 12px 0; }
  .doctors-list-block .portfolio_block {
    font-size: 12px; }
  .doctors-list-block .btn_wrap {
    padding-top: 12px; }
    .doctors-list-block .btn_wrap a {
      -webkit-transition: 0.2s;
      transition: 0.2s;
      display: inline-block;
      cursor: pointer;
      text-decoration: none;
      font-family: "Montserrat";
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
      text-align: center;
      font-weight: 500;
      border-radius: 5px;
      position: relative;
      z-index: 2;
      overflow: hidden;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      font-size: 14px;
      padding: 11px 20px;
      background: #fff;
      color: #457C00;
      border: 1px solid #457C00; }
      .doctors-list-block .btn_wrap a:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0px;
        right: 0;
        bottom: 0;
        z-index: -1;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        -webkit-transform: scaleY(0);
                transform: scaleY(0); }
      .doctors-list-block .btn_wrap a:hover:before {
        -webkit-transform: scale(2);
                transform: scale(2); }
      .doctors-list-block .btn_wrap a:before {
        background: #457C00; }
      .doctors-list-block .btn_wrap a:hover {
        color: #fff; }
      .doctors-list-block .btn_wrap a:active {
        color: #294900;
        border-color: #294900; }

.page-doctor .block-system-main {
  max-width: none; }

.page-doctor .top_line_block {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(-50.21%, rgba(0, 0, 0, 0.3)), color-stop(29.9%, rgba(0, 0, 0, 0))), rgba(255, 255, 255, 0.1);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) -50.21%, rgba(0, 0, 0, 0) 29.9%), rgba(255, 255, 255, 0.1);
  margin-bottom: -113px;
  position: relative;
  z-index: 1; }

.doctor_preview {
  background: url(../images/bg-doc.png) no-repeat center center;
  background-size: cover; }
  .doctor_preview .conteiner {
    padding-top: 113px;
    max-width: 1800px;
    margin: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .doctor_preview .title_node {
    line-height: 55px;
    font-size: 36px;
    font-weight: 700;
    color: #FFFFFF;
    padding-bottom: 44px; }
  .doctor_preview .characteristic_item {
    line-height: 18px;
    font-size: 12px;
    color: #FFFFFF; }
    .doctor_preview .characteristic_item .label_item {
      font-weight: 700; }
  .doctor_preview .btn_wrapper {
    margin: 0 -13px;
    padding-top: 22px; }
    .doctor_preview .btn_wrapper .btn_item {
      padding: 0 13px;
      display: inline-block;
      vertical-align: top; }
      .doctor_preview .btn_wrapper .btn_item a {
        -webkit-transition: 0.2s;
        transition: 0.2s;
        display: inline-block;
        cursor: pointer;
        text-decoration: none;
        font-family: "Montserrat";
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
        text-align: center;
        font-weight: 500;
        border-radius: 5px;
        position: relative;
        z-index: 2;
        overflow: hidden;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        font-size: 14px;
        padding: 11px 20px;
        background: #fff;
        color: #457C00;
        border: 1px solid #457C00;
        border: none; }
        .doctor_preview .btn_wrapper .btn_item a:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0px;
          right: 0;
          bottom: 0;
          z-index: -1;
          -webkit-transition: 0.5s;
          transition: 0.5s;
          -webkit-transform: scaleY(0);
                  transform: scaleY(0); }
        .doctor_preview .btn_wrapper .btn_item a:hover:before {
          -webkit-transform: scale(2);
                  transform: scale(2); }
        .doctor_preview .btn_wrapper .btn_item a:before {
          background: #457C00; }
        .doctor_preview .btn_wrapper .btn_item a:hover {
          color: #fff; }
        .doctor_preview .btn_wrapper .btn_item a:active {
          color: #294900;
          border-color: #294900; }

.detail_informations_block {
  max-width: 1800px;
  margin: auto;
  padding-top: 40px; }

.informations_block {
  background: #FFFFFF;
  -webkit-box-shadow: 0px 10px 75px rgba(24, 26, 61, 0.1);
          box-shadow: 0px 10px 75px rgba(24, 26, 61, 0.1); }

.informations_title {
  font-weight: 600;
  line-height: 37px;
  font-size: 24px;
  color: #457C00;
  padding: 33px 40px;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 10px 75px rgba(24, 26, 61, 0.1);
          box-shadow: 0px 10px 75px rgba(24, 26, 61, 0.1); }

.informations_content {
  padding: 33px 40px; }
  .informations_content .field_item {
    padding-left: 50px;
    position: relative;
    padding-bottom: 30px; }
    .informations_content .field_item:before {
      position: absolute;
      content: '';
      width: 18px;
      height: 18px;
      left: 0;
      border-radius: 50%;
      border: 2px solid #457C00;
      background: #ffffff;
      z-index: 2; }
    .informations_content .field_item::after {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      left: 9px;
      border: 2px solid #457C00;
      z-index: 1; }
    .informations_content .field_item:last-child::after {
      content: none; }

.experience_informations_block .field_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .experience_informations_block .field_item .experience_first_item {
    width: 276px;
    margin-right: 30px;
    -ms-flex-negative: 0;
        flex-shrink: 0; }
  .experience_informations_block .field_item .experience_second_item {
    max-width: 880px; }

.about_doctor .informations_desc {
  max-width: 1240px; }

.registered_doctor_block .informations_content {
  padding-top: 25px;
  margin: 0 -15px; }

.registered_doctor_block .registered_item {
  display: inline-block;
  vertical-align: top;
  padding: 15px; }
  .registered_doctor_block .registered_item .item_label {
    line-height: 21px;
    font-size: 14px;
    text-align: justify;
    padding-bottom: 10px; }
  .registered_doctor_block .registered_item .phone_item {
    line-height: 27px;
    font-size: 18px;
    padding-bottom: 5px; }

.registered_doctor_block .item_label a {
  text-decoration: underline; }

h1 {
  margin: 0; }

.importantly_block {
  line-height: 27px;
  font-size: 18px;
  text-align: center;
  max-width: 650px;
  margin: auto; }
  .importantly_block .importantly_title {
    font-weight: 700; }
  .importantly_block .importantly_subtitle {
    font-weight: 600;
    padding-top: 25px; }

.detail_informations_wrapper {
  padding-bottom: 40px; }

.tariff_informations_block {
  padding-top: 30px; }
  .tariff_informations_block .img_item {
    float: left;
    padding-right: 30px;
    padding-bottom: 15px; }
  .tariff_informations_block .desc_item {
    text-align: justify; }

.tariff_page .block-system-main {
  -webkit-box-shadow: none;
          box-shadow: none; }

.clearfix {
  clear: both; }

.tariff_title {
  background: #FFFFFF;
  padding: 22px 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-shadow: 0px 0px 45px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 0px 45px rgba(0, 0, 0, 0.05);
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative; }
  .tariff_title .tariff_letter {
    line-height: 41px;
    color: #457C00;
    font-weight: 700;
    font-size: 24px; }
  .tariff_title .field_title {
    padding-left: 25px;
    font-weight: 600;
    line-height: 27px;
    font-size: 18px; }
  .tariff_title .arrow {
    background: url(../images/chevorn-down.svg) no-repeat center center;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    -webkit-transition: 0.2s;
    transition: 0.2s; }
    .tariff_title .arrow.active {
      -webkit-transform: rotatex(180deg);
              transform: rotatex(180deg); }

.list_price {
  -webkit-box-shadow: 0px 0px 45px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 0px 45px rgba(0, 0, 0, 0.05); }
  .list_price .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 18px 20px 18px 35px; }
  .list_price .inform_desc {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg);
    -webkit-box-shadow: 0px 10px 45px rgba(0, 0, 0, 0.15);
            box-shadow: 0px 10px 45px rgba(0, 0, 0, 0.15);
    position: absolute;
    width: 455px;
    padding: 10px 30px;
    background: #FFFFFF;
    border-radius: 5px;
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    right: -37px;
    top: -51px; }
    .list_price .inform_desc:before {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      border-left: 11px solid transparent;
      border-right: 11px solid transparent;
      border-top: 7px solid #ffffff;
      right: 34px;
      bottom: -7px; }

.price_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 12px;
  padding-bottom: 10px; }
  .price_item .desc_pricilage {
    margin-right: 10px;
    position: relative; }
    .price_item .desc_pricilage:hover .icone_desc {
      background: url(../images/і.svg) no-repeat center center; }
    .price_item .desc_pricilage:hover .inform_desc {
      -webkit-transform-origin: center bottom;
              transform-origin: center bottom;
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg); }
  .price_item .icone_desc {
    font-size: 0px;
    background: url(../images/desc_i.svg) no-repeat center center;
    width: 15px;
    height: 15px;
    cursor: pointer;
    -webkit-transition: 0.2s;
    transition: 0.2s; }
  .price_item .price {
    padding-left: 10px;
    font-weight: 700;
    color: #457C00; }

.list_price {
  max-height: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  overflow: hidden; }
  .list_price.active {
    max-height: 999px; }

.document_wrapper {
  padding: 20px 0;
  text-align: center; }
  .document_wrapper .doc_linc {
    -webkit-transition: 0.2s;
    transition: 0.2s;
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    font-family: "Montserrat";
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    text-align: center;
    font-weight: 500;
    border-radius: 5px;
    position: relative;
    z-index: 2;
    overflow: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    font-size: 14px;
    padding: 11px 20px;
    color: #fff;
    background: #457C00;
    border: 1px solid #457C00; }
    .document_wrapper .doc_linc:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0px;
      right: 0;
      bottom: 0;
      z-index: -1;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      -webkit-transform: scaleY(0);
              transform: scaleY(0); }
    .document_wrapper .doc_linc:hover:before {
      -webkit-transform: scale(2);
              transform: scale(2); }
    .document_wrapper .doc_linc:before {
      background: #fff; }
    .document_wrapper .doc_linc:hover {
      color: #457C00;
      border-color: #457C00; }
    .document_wrapper .doc_linc:active {
      color: #294900;
      border-color: #294900;
      text-shadow: none; }

.diagnostics_conteiner {
  padding: 15px;
  font-size: 0; }
  .diagnostics_conteiner .diagnostics_row {
    display: inline-block;
    vertical-align: top;
    padding: 15px;
    width: 20%;
    font-size: 14px;
    position: relative;
    text-align: center; }
    .diagnostics_conteiner .diagnostics_row .field_title {
      -webkit-transition: 0.2s;
      transition: 0.2s;
      padding-top: 15px; }
    .diagnostics_conteiner .diagnostics_row:hover .field_title {
      color: #457C00; }
  .diagnostics_conteiner .field_link a {
    font-size: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0; }

.pager-item-list ul {
  margin: 0;
  text-align: center;
  margin: 30px 0;
  font-size: 14px;
  line-height: 32px; }
  .pager-item-list ul li {
    margin: 0 5px;
    display: inline-block;
    color: #333;
    min-width: 32px;
    position: relative;
    padding: 0;
    vertical-align: middle;
    text-align: center; }
    .pager-item-list ul li a {
      padding: 0 5px;
      display: block;
      background: #FCFCFD;
      /* Light Shade */
      border: 1px solid #D7D6DA;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      border-radius: 3px;
      -webkit-transition: 0.2s;
      transition: 0.2s;
      color: #333;
      text-decoration: none;
      height: 32px; }
      .pager-item-list ul li a:hover {
        border: 1px solid #457C00;
        color: #457C00;
        font-weight: 600; }
    .pager-item-list ul li.pager-ellipsis {
      background: url(../images/point.svg) no-repeat center center;
      font-size: 0;
      border: 1px solid #D7D6DA;
      border-radius: 3px; }
    .pager-item-list ul li.pager-current {
      min-width: 32px;
      height: 32px;
      padding: 0 5px;
      background: #F3F3F9;
      /* Accent */
      border: 1px solid #457C00;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      border-radius: 3px;
      font-weight: 600;
      color: #457C00; }
    .pager-item-list ul li.pager-last a {
      background: #F3F3F9;
      color: #D7D6DA;
      border: 1px solid #F3F3F9; }
    .pager-item-list ul li.pager-previous a, .pager-item-list ul li.pager-next a {
      width: 52px;
      height: 32px;
      font-size: 0;
      position: relative; }
      .pager-item-list ul li.pager-previous a:before, .pager-item-list ul li.pager-next a:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        -webkit-transition: 0.2s;
        transition: 0.2s; }
      .pager-item-list ul li.pager-previous a:hover:before, .pager-item-list ul li.pager-next a:hover:before {
        opacity: 1; }
    .pager-item-list ul li.pager-previous a {
      background: url(../images/arrow_pager-1.svg) no-repeat scroll center center, #FCFCFD; }
      .pager-item-list ul li.pager-previous a:before {
        background: url(../images/arrow_pager-4.svg) no-repeat scroll center center #457C00; }
    .pager-item-list ul li.pager-next a {
      background: url(../images/arrow_pager-3.svg) no-repeat scroll center center, #FCFCFD; }
      .pager-item-list ul li.pager-next a:before {
        background: url(../images/arrow_pager-2.svg) no-repeat scroll center center #457C00; }

.diagnostics-slider .row-wrapper {
  position: relative; }

.diagnostics-slider .slick-dots {
  right: 0;
  left: 0;
  margin: auto;
  max-width: 1170px;
  bottom: 80px; }

.information_slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 1170px;
  margin: auto; }
  .information_slider .information_wrap {
    max-width: 475px;
    color: #fff;
    padding-top: 136px; }
  .information_slider .slide_title {
    font-weight: 800;
    line-height: 55px;
    font-size: 36px;
    padding-bottom: 40px; }
  .information_slider .slide_desc {
    font-size: 18px;
    padding-bottom: 25px; }
  .information_slider .slide_lin a {
    -webkit-transition: 0.2s;
    transition: 0.2s;
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    font-family: "Montserrat";
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    text-align: center;
    font-weight: 500;
    border-radius: 5px;
    position: relative;
    z-index: 2;
    overflow: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    font-size: 14px;
    padding: 11px 20px;
    color: #fff;
    background: #457C00;
    border: 1px solid #457C00; }
    .information_slider .slide_lin a:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0px;
      right: 0;
      bottom: 0;
      z-index: -1;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      -webkit-transform: scaleY(0);
              transform: scaleY(0); }
    .information_slider .slide_lin a:hover:before {
      -webkit-transform: scale(2);
              transform: scale(2); }
    .information_slider .slide_lin a:before {
      background: #fff; }
    .information_slider .slide_lin a:hover {
      color: #457C00;
      border-color: #457C00; }
    .information_slider .slide_lin a:active {
      color: #294900;
      border-color: #294900;
      text-shadow: none; }

.slide_img {
  position: relative; }
  .slide_img:before {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: -webkit-gradient(linear, left top, right top, from(#000000), color-stop(60.49%, rgba(0, 0, 0, 0)));
    background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 60.49%); }
  .slide_img img {
    -o-object-fit: cover;
       object-fit: cover;
    width: 100%;
    height: 600px; }

.causes_illness_block {
  margin: auto;
  padding-top: 50px;
  padding-bottom: 70px;
  max-width: 1800px; }

.couser_conteiner {
  padding-top: 50px;
  margin: 0 -15px;
  font-size: 0; }
  .couser_conteiner .couser_row {
    display: inline-block;
    width: 25%;
    vertical-align: top;
    padding: 15px;
    text-align: center;
    font-size: 14px; }
    .couser_conteiner .couser_row .img_wrap {
      padding-bottom: 10px; }
    .couser_conteiner .couser_row .img_wrap img {
      -o-object-fit: cover;
         object-fit: cover;
      width: 125px;
      height: 125px;
      border-radius: 50%; }

.diagnostics_block_info {
  background: url(../images/bg-specialists-block.jpg); }
  .diagnostics_block_info .container {
    padding-top: 75px;
    padding-bottom: 75px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
  .diagnostics_block_info .block_desc {
    max-width: 700px;
    margin: auto;
    text-align: justify; }
  .diagnostics_block_info .btn_linc {
    padding-top: 30px; }
    .diagnostics_block_info .btn_linc a {
      -webkit-transition: 0.2s;
      transition: 0.2s;
      display: inline-block;
      cursor: pointer;
      text-decoration: none;
      font-family: "Montserrat";
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
      text-align: center;
      font-weight: 500;
      border-radius: 5px;
      position: relative;
      z-index: 2;
      overflow: hidden;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      font-size: 14px;
      padding: 11px 20px;
      color: #fff;
      background: #457C00;
      border: 1px solid #457C00; }
      .diagnostics_block_info .btn_linc a:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0px;
        right: 0;
        bottom: 0;
        z-index: -1;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        -webkit-transform: scaleY(0);
                transform: scaleY(0); }
      .diagnostics_block_info .btn_linc a:hover:before {
        -webkit-transform: scale(2);
                transform: scale(2); }
      .diagnostics_block_info .btn_linc a:before {
        background: #fff; }
      .diagnostics_block_info .btn_linc a:hover {
        color: #457C00;
        border-color: #457C00; }
      .diagnostics_block_info .btn_linc a:active {
        color: #294900;
        border-color: #294900;
        text-shadow: none; }

.survey_plan_block {
  max-width: 1800px;
  margin: auto;
  padding-top: 70px;
  padding-bottom: 70px; }
  .survey_plan_block .block_title {
    margin: auto;
    max-width: 760px;
    padding-bottom: 30px; }
  .survey_plan_block .container {
    margin: 0 -15px;
    font-size: 0; }
    .survey_plan_block .container .plan_row {
      display: inline-block;
      vertical-align: top;
      width: 33.333%;
      font-size: 14px;
      padding: 0 15px; }
  .survey_plan_block .img_block {
    color: #fff;
    background: #27AA1C;
    border-radius: 5px;
    min-height: 320px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    .survey_plan_block .img_block .title {
      font-weight: 700;
      line-height: 37px;
      font-size: 24px;
      text-align: center; }
  .survey_plan_block .list_plan .title_list {
    padding: 25px 0;
    line-height: 27px;
    font-size: 18px;
    font-weight: 700;
    color: #457C00; }
  .survey_plan_block .price_list .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 7px 0; }
  .survey_plan_block .price_list .item_title {
    padding-left: 17px;
    position: relative; }
    .survey_plan_block .price_list .item_title:before {
      position: absolute;
      content: '';
      width: 8px;
      height: 8px;
      background: #457C00;
      border-radius: 50%;
      left: 0;
      top: 4px; }
  .survey_plan_block .price_list .price {
    font-weight: 700; }
  .survey_plan_block .row_desc {
    padding-top: 20px;
    font-weight: 500;
    line-height: 18px;
    font-size: 12px;
    color: #666666; }
  .survey_plan_block .serveu_plan_link {
    padding-top: 50px;
    text-align: center; }
    .survey_plan_block .serveu_plan_link a {
      -webkit-transition: 0.2s;
      transition: 0.2s;
      display: inline-block;
      cursor: pointer;
      text-decoration: none;
      font-family: "Montserrat";
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
      text-align: center;
      font-weight: 500;
      border-radius: 5px;
      position: relative;
      z-index: 2;
      overflow: hidden;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      font-size: 14px;
      padding: 11px 20px;
      background: #fff;
      color: #457C00;
      border: 1px solid #457C00; }
      .survey_plan_block .serveu_plan_link a:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0px;
        right: 0;
        bottom: 0;
        z-index: -1;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        -webkit-transform: scaleY(0);
                transform: scaleY(0); }
      .survey_plan_block .serveu_plan_link a:hover:before {
        -webkit-transform: scale(2);
                transform: scale(2); }
      .survey_plan_block .serveu_plan_link a:before {
        background: #457C00; }
      .survey_plan_block .serveu_plan_link a:hover {
        color: #fff; }
      .survey_plan_block .serveu_plan_link a:active {
        color: #294900;
        border-color: #294900; }

.doctor_view {
  max-width: 1800px;
  margin: auto; }
  .doctor_view .doctors-list-block {
    margin: 0 -15px; }

.map {
  width: 100%;
  height: 500px; }

.gm-style-iw {
  background: #Fff;
  -webkit-box-shadow: -14px 0px 0px #fff, -14px 9px 0px #fff, -14px -8px 0px #fff, 15px -8px 0px #fff, 15px 9px 0px #fff;
          box-shadow: -14px 0px 0px #fff, -14px 9px 0px #fff, -14px -8px 0px #fff, 15px -8px 0px #fff, 15px 9px 0px #fff; }

.gm-style-iw {
  overflow: visible !important; }
  .gm-style-iw div {
    overflow: visible !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    height: 100%; }
  .gm-style-iw + div {
    display: none; }

.gm-style-iw {
  width: 330px !important;
  height: 212px; }
  .gm-style-iw::before {
    content: '';
    background: url(../images/map_img.png) no-repeat center center;
    position: absolute;
    background-size: contain;
    left: -16px;
    right: -16px;
    bottom: 0;
    top: -78px;
    z-index: 0;
    bottom: 0;
    z-index: 0; }
  .gm-style-iw::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border: 22px solid transparent;
    border-top-color: #fff;
    z-index: 4;
    top: auto;
    bottom: -53px;
    left: 42%;
    margin: auto; }
  .gm-style-iw #bodyContent {
    width: 300px;
    /* text-align: center; */
    font-size: 16px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin: auto; }

.phone_wrapper {
  font-size: 0;
  max-width: 1020px;
  margin: auto;
  padding: 40px 0; }
  .phone_wrapper .row_phone {
    font-size: 14px;
    display: inline-block;
    vertical-align: top;
    width: 25%;
    padding: 15px; }
  .phone_wrapper .title_phone {
    font-weight: 600;
    line-height: 21px;
    font-size: 14px;
    padding-bottom: 20px;
    color: #666666; }
  .phone_wrapper .phone_item a {
    font-weight: 600;
    line-height: 27px;
    font-size: 18px;
    color: #333333;
    position: relative;
    padding-left: 30px; }
    .phone_wrapper .phone_item a:before {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      width: 20px;
      height: 20px; }
    .phone_wrapper .phone_item a:hover {
      text-decoration: none; }
  .phone_wrapper .multi .phone_item a:before {
    background: url(../images/phone1.svg) no-repeat center center; }
  .phone_wrapper .kyivstar .phone_item a:before {
    background: url(../images/phone2.svg) no-repeat center center; }
  .phone_wrapper .lifecell .phone_item a:before {
    background: url(../images/phone3.svg) no-repeat center center; }
  .phone_wrapper .vodafone .phone_item a:before {
    background: url(../images/phone4.svg) no-repeat center center; }

.with_questions_block .row_with_questions {
  padding: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  .with_questions_block .row_with_questions:nth-of-type(even) {
    background: #F9F8FC;
    position: relative; }
    .with_questions_block .row_with_questions:nth-of-type(even):before {
      left: 0;
      top: 0;
      bottom: 0;
      width: 60%;
      position: absolute;
      content: '';
      background: #F3F3F9; }

.with_questions_block .field_first {
  max-width: 470px;
  width: 100%;
  margin: 50px;
  font-weight: 500;
  line-height: 27px;
  font-size: 18px;
  z-index: 1; }

.with_questions_block .field_second {
  margin: 50px;
  width: 100%;
  max-width: 300px;
  z-index: 1; }

.contacts_page .block-system-main {
  margin-bottom: 70px; }

.contacts_page .header_contenc_page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  .contacts_page .header_contenc_page .link_heder_page {
    -webkit-transition: 0.2s;
    transition: 0.2s;
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    font-family: "Montserrat";
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    text-align: center;
    font-weight: 500;
    border-radius: 5px;
    position: relative;
    z-index: 2;
    overflow: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    font-size: 14px;
    padding: 11px 20px;
    background: #fff;
    color: #457C00;
    border: 1px solid #457C00;
    padding: 0px 20px;
    border-radius: 0; }
    .contacts_page .header_contenc_page .link_heder_page:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0px;
      right: 0;
      bottom: 0;
      z-index: -1;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      -webkit-transform: scaleY(0);
              transform: scaleY(0); }
    .contacts_page .header_contenc_page .link_heder_page:hover:before {
      -webkit-transform: scale(2);
              transform: scale(2); }
    .contacts_page .header_contenc_page .link_heder_page:before {
      background: #457C00; }
    .contacts_page .header_contenc_page .link_heder_page:hover {
      color: #fff; }
    .contacts_page .header_contenc_page .link_heder_page:active {
      color: #294900;
      border-color: #294900; }

.online_page .online_page_container {
  padding: 15px;
  font-size: 0; }
  .online_page .online_page_container .online_test_row {
    display: inline-block;
    vertical-align: top;
    width: 20%;
    font-size: 14px;
    padding: 15px;
    text-align: center; }
  .online_page .online_page_container .row_title {
    padding: 15px 0;
    font-size: 18px; }
  .online_page .online_page_container .row_licnk a {
    -webkit-transition: 0.2s;
    transition: 0.2s;
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    font-family: "Montserrat";
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    text-align: center;
    font-weight: 500;
    border-radius: 5px;
    position: relative;
    z-index: 2;
    overflow: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    font-size: 14px;
    padding: 11px 20px;
    background: #fff;
    color: #457C00;
    border: 1px solid #457C00;
    padding-top: 6px;
    padding-bottom: 6px;
    border: 2px solid; }
    .online_page .online_page_container .row_licnk a:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0px;
      right: 0;
      bottom: 0;
      z-index: -1;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      -webkit-transform: scaleY(0);
              transform: scaleY(0); }
    .online_page .online_page_container .row_licnk a:hover:before {
      -webkit-transform: scale(2);
              transform: scale(2); }
    .online_page .online_page_container .row_licnk a:before {
      background: #457C00; }
    .online_page .online_page_container .row_licnk a:hover {
      color: #fff; }
    .online_page .online_page_container .row_licnk a:active {
      color: #294900;
      border-color: #294900; }

.online-type .block-system-main {
  -webkit-box-shadow: none;
          box-shadow: none; }

.online-type .online_page {
  -webkit-box-shadow: 0px 10px 75px rgba(24, 26, 61, 0.1);
          box-shadow: 0px 10px 75px rgba(24, 26, 61, 0.1); }

.online_test_content {
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px; }

.line_block {
  max-width: 1100px;
  width: 100%;
  position: relative;
  height: 8px;
  background: #F2F3F9;
  border-radius: 20px; }

.progres_block {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: #457C00;
  border-radius: 20px; }
  .progres_block:after {
    position: absolute;
    content: '';
    right: 0;
    width: 22px;
    height: 22px;
    top: -7px;
    background: #457C00;
    border-radius: 50%; }

.count_question {
  padding: 30px 0;
  display: block;
  max-width: 1100px;
  text-align: right;
  padding-right: 140px; }

input[type="radio"],
input[type="checkbox"] {
  display: none; }

input[type="checkbox"] ~ label.option,
input[type="radio"] ~ label.option {
  padding: 10px;
  display: block;
  padding-left: 30px;
  position: relative; }

input[type="checkbox"] ~ label.option:before,
input[type="radio"] ~ label.option:before {
  position: absolute;
  content: '';
  width: 18px;
  height: 18px;
  border: 1px solid #457C00;
  left: 0;
  border-radius: 4px; }

input[type="checkbox"]:checked ~ label.option:before,
input[type="radio"]:checked ~ label.option:before {
  background: url(../images/check_box.svg) no-repeat center center; }

.main_conten_form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 50px 0; }
  .main_conten_form .left_block,
  .main_conten_form .right_block {
    padding: 20px; }
  .main_conten_form .question_block {
    max-width: 1120px;
    font-size: 22px;
    font-weight: 500;
    padding-bottom: 25px; }
  .main_conten_form .form-checkboxes {
    padding-bottom: 26px; }
  .main_conten_form .from_action .form_submit {
    -webkit-transition: 0.2s;
    transition: 0.2s;
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    font-family: "Montserrat";
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    text-align: center;
    font-weight: 500;
    border-radius: 5px;
    position: relative;
    z-index: 2;
    overflow: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    font-size: 18px;
    padding: 12px 45px;
    background: #fff;
    color: #457C00;
    border: 1px solid #457C00;
    background: #fff;
    -webkit-appearance: none; }
    .main_conten_form .from_action .form_submit:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0px;
      right: 0;
      bottom: 0;
      z-index: -1;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      -webkit-transform: scaleY(0);
              transform: scaleY(0); }
    .main_conten_form .from_action .form_submit:hover:before {
      -webkit-transform: scale(2);
              transform: scale(2); }
    .main_conten_form .from_action .form_submit:before {
      background: #457C00; }
    .main_conten_form .from_action .form_submit:hover {
      color: #fff; }
    .main_conten_form .from_action .form_submit:active {
      color: #294900;
      border-color: #294900; }
    .main_conten_form .from_action .form_submit:hover {
      background: #457C00; }

.service_linck {
  text-decoration: underline; }
  .service_linck:hover {
    color: #457C00; }

.service_views .linck_block .link_item {
  padding: 5px 0; }

.information_block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  padding: 20px;
  border-bottom: 1px solid #E7ECF1; }
  .information_block .img_wrap,
  .information_block .desc_wrap {
    padding: 20px; }

.add_information {
  padding: 40px 20px;
  font-size: 0;
  text-align: center; }
  .add_information .title {
    color: #457C00;
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    padding-left: 20px; }
  .add_information .field_title {
    text-align: left;
    padding: 20px 0; }
  .add_information .container {
    font-size: 0; }
  .add_information .apply_block,
  .add_information .overview_block {
    display: inline-block;
    vertical-align: top; }
    .add_information .apply_block .overview_row,
    .add_information .apply_block .apply_row,
    .add_information .overview_block .overview_row,
    .add_information .overview_block .apply_row {
      display: inline-block;
      vertical-align: top;
      max-width: 320px;
      margin: 15px;
      font-size: 14px; }
  .add_information .add_desc {
    font-size: 14px;
    text-align: justify;
    padding: 0 20px; }

.galery_content {
  margin: 15px;
  font-size: 0; }
  .galery_content .galery_row {
    display: inline-block;
    width: 25%;
    vertical-align: top;
    font-size: 14px;
    padding: 40px 15px;
    position: relative;
    border-bottom: 1px solid #F0F0F6; }
    .galery_content .galery_row:hover .field_title {
      color: #457C00; }
  .galery_content .count_img {
    font-weight: 500;
    line-height: 18px;
    font-size: 12px;
    font-style: italic;
    color: #666666; }
  .galery_content .field_title {
    -webkit-transition: 0.2s;
    transition: 0.2s;
    font-weight: 600;
    line-height: 27px;
    font-size: 18px; }
  .galery_content .link_row a {
    position: absolute;
    left: 15px;
    top: 15px;
    right: 15px;
    bottom: 15px;
    font-size: 0; }

.galery-type .block-system-main {
  -webkit-box-shadow: none;
          box-shadow: none; }

.galery-type .galery_page {
  -webkit-box-shadow: 0px 10px 75px rgba(24, 26, 61, 0.1);
          box-shadow: 0px 10px 75px rgba(24, 26, 61, 0.1); }

.galery_title {
  text-align: center;
  color: #457C00;
  font-weight: 700;
  font-size: 22px;
  padding-top: 20px; }

.prev_glalery_inform_block {
  max-width: 1000px;
  margin: auto; }
  .prev_glalery_inform_block .count_img {
    font-weight: 500;
    line-height: 18px;
    font-size: 12px;
    font-style: italic;
    color: #666666;
    padding-bottom: 10px; }
  .prev_glalery_inform_block .field_title {
    font-size: 18px;
    font-weight: 600; }
  .prev_glalery_inform_block .right_block {
    padding-left: 30px; }

.galery_prew {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.desc_galery {
  padding: 10px 0; }

.slide_big .item img {
  width: 1000px;
  height: 605px;
  -o-object-fit: cover;
     object-fit: cover; }

.slide_big .slick-arrow {
  width: 64px;
  height: 64px;
  background: #457C00;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  font-size: 0;
  position: absolute;
  top: auto;
  border: 1px solid #457C00;
  bottom: -80px;
  -webkit-transition: 0.2s;
  transition: 0.2s; }
  .slide_big .slick-arrow:hover {
    background: #fff; }
  .slide_big .slick-arrow.slick-prev {
    left: 0;
    background: url(../images/arrow_pager-4.svg) no-repeat scroll center center #457C00; }
    .slide_big .slick-arrow.slick-prev:hover {
      background: url(../images/arrow_pager-1.svg) no-repeat scroll center center, #fff; }
  .slide_big .slick-arrow.slick-next {
    right: 0;
    background: url(../images/arrow_pager-2.svg) no-repeat scroll center center #457C00; }
    .slide_big .slick-arrow.slick-next:hover {
      background: url(../images/arrow_pager-3.svg) no-repeat scroll center center, #FFF; }

.slide_min {
  margin: auto;
  max-width: 835px;
  padding-top: 15px; }
  .slide_min .item img {
    cursor: pointer;
    width: 65px;
    height: 65px;
    -o-object-fit: cover;
       object-fit: cover; }

.galery_slider {
  max-width: 1000px;
  margin: auto;
  padding-top: 40px; }

.midle_galery {
  padding-top: 50px;
  padding-bottom: 40px;
  -webkit-box-shadow: 0px 10px 75px rgba(24, 26, 61, 0.1);
          box-shadow: 0px 10px 75px rgba(24, 26, 61, 0.1);
  margin-bottom: 50px; }

.galery_view {
  padding-top: 30px;
  border-top: 1px solid #F0F0F6; }

.midle_desc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: justify; }
  .midle_desc .left_block,
  .midle_desc .right_block {
    padding: 20px; }

.history-type .top_desc,
.history-type .bottom_desc {
  padding: 0px 40px; }

.history-type .top_desc {
  padding-top: 40px; }

.history-type .bottom_desc {
  padding-bottom: 40px; }

.search_form .from_action input[type="submit"] {
  -webkit-transition: 0.2s;
  transition: 0.2s;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  font-family: "Montserrat";
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  text-align: center;
  font-weight: 500;
  border-radius: 5px;
  position: relative;
  z-index: 2;
  overflow: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 18px;
  padding: 12px 45px;
  color: #fff;
  background: #457C00;
  border: 1px solid #457C00; }
  .search_form .from_action input[type="submit"]:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0px;
    right: 0;
    bottom: 0;
    z-index: -1;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    -webkit-transform: scaleY(0);
            transform: scaleY(0); }
  .search_form .from_action input[type="submit"]:hover:before {
    -webkit-transform: scale(2);
            transform: scale(2); }
  .search_form .from_action input[type="submit"]:before {
    background: #fff; }
  .search_form .from_action input[type="submit"]:hover {
    color: #457C00;
    border-color: #457C00; }
  .search_form .from_action input[type="submit"]:active {
    color: #294900;
    border-color: #294900;
    text-shadow: none; }
  .search_form .from_action input[type="submit"]:hover {
    background: #ffffff; }

.search_form .bg_search {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  overflow: hidden;
  position: fixed;
  background: #000;
  opacity: .6; }

.search_form .container {
  z-index: 4; }

.about_page_conteiner .block_contacts {
  padding-bottom: 70px;
  padding-top: 50px; }

.about_page_conteiner .desc_block {
  padding: 40px;
  border-bottom: 1px solid #E8EDF2; }

.about_page_conteiner .img_wrap {
  float: left;
  padding-right: 30px;
  padding-bottom: 30px; }

.about_page_conteiner .desc_wrap {
  text-align: justify; }

.about_page_conteiner .tob_block {
  font-size: 18px;
  padding-bottom: 25px; }

.about_page_conteiner .add_decs {
  clear: both; }

.block_contact_content {
  max-width: 845px;
  margin: auto; }
  .block_contact_content .item_contect {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 0px 30px; }
    .block_contact_content .item_contect:nth-of-type(odd) {
      background: #F9F8FC; }
      .block_contact_content .item_contect:nth-of-type(odd) .title_contact:after {
        position: absolute;
        content: '';
        width: 290px;
        left: -30px;
        top: 0;
        bottom: 0;
        background: #F2F3F9;
        z-index: -1; }
  .block_contact_content .title_contact {
    max-width: 375px;
    width: 100%;
    position: relative;
    padding: 20px 0;
    font-weight: 500;
    line-height: 27px;
    font-size: 18px;
    z-index: 1; }
    .block_contact_content .title_contact:before {
      position: absolute;
      content: '';
      background: url(../images/shadow_list.png) no-repeat center center;
      right: 0;
      top: 0;
      bottom: 0;
      width: 42px; }
  .block_contact_content .title {
    width: 100%;
    padding: 20px; }
    .block_contact_content .title .list {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      font-size: 12px;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
    .block_contact_content .title .label_item {
      font-weight: 500;
      line-height: 21px;
      font-size: 14px; }

.goal_title {
  font-weight: 600;
  line-height: 37px;
  font-size: 24px;
  text-align: center;
  color: #457C00; }

.goal_block {
  border-top: 1px solid #F0F0F6;
  padding: 25px 0; }
  .goal_block .goal_conteiner {
    max-width: 850px;
    margin: auto; }
  .goal_block .goal_row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 25px 0;
    position: relative;
    z-index: 1; }
    .goal_block .goal_row:before {
      content: '';
      position: absolute;
      top: 61px;
      height: 100%;
      left: 32px;
      border: 2px solid #457C00;
      z-index: -1; }
    .goal_block .goal_row:last-child:before {
      content: none; }
  .goal_block .icone_wrap {
    width: 70px;
    height: 70px;
    border: 5px solid #FFFFFF;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-radius: 50%;
    background: #457C00;
    -webkit-box-shadow: 0px 10px 55px rgba(30, 55, 63, 0.25);
            box-shadow: 0px 10px 55px rgba(30, 55, 63, 0.25);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .goal_block .goal_desc {
    max-width: 770px;
    width: 100%;
    padding-left: 30px; }

.description_block {
  padding: 25px 40px;
  border-top: 1px solid #F0F0F6; }
  .description_block .first_desc {
    font-size: 18px;
    padding: 25px 0; }
  .description_block .second_desc {
    padding: 25px 0; }

.video_conteiner {
  padding: 0 40px; }
  .video_conteiner .video_row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 40px 0;
    border-bottom: 1px solid #F0F0F6; }
  .video_conteiner .desc_wrap {
    padding-left: 30px; }
  .video_conteiner .field_title {
    font-weight: 500;
    line-height: 27px;
    font-size: 18px;
    padding-bottom: 30px; }

.video_wrap {
  max-width: 640px;
  width: 100%; }

.about_use_conteiner {
  padding: 20px 0; }

.about-use-type .block-system-main {
  -webkit-box-shadow: none;
          box-shadow: none; }

.about-use-type .desc_page {
  padding: 30px 0; }

.about-use-type .add_img {
  font-size: 0;
  margin: 0 -15px; }
  .about-use-type .add_img .item {
    display: inline-block;
    vertical-align: top;
    width: 20%;
    padding: 0 15px; }

.career_row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 21px;
  border-bottom: 1px solid #F0F0F6; }
  .career_row .right_block {
    padding-left: 30px; }
  .career_row .salary_block {
    font-weight: 500;
    line-height: 18px;
    font-size: 12px;
    color: #666666; }
  .career_row .field_title {
    font-weight: 600;
    line-height: 27px;
    font-size: 18px;
    padding-bottom: 10px; }

.standart_conteiner {
  font-size: 0;
  padding: 50px 0 0; }

.standart_row {
  padding: 0 21px;
  font-size: 14px;
  display: inline-block;
  width: 20%;
  padding: 20px 21px;
  border-bottom: 1px solid #F0F0F6; }
  .standart_row img {
    width: 315px;
    height: 445px;
    -o-object-fit: cover;
       object-fit: cover; }
  .standart_row .field_title {
    padding: 10px 0;
    font-size: 18px;
    font-weight: 500; }

.scan_row {
  display: inline-block;
  width: 20%;
  vertical-align: top;
  padding: 15px 21px;
  font-size: 14px; }
  .scan_row .desc_wrap {
    background: -webkit-gradient(linear, left top, left bottom, from(#FFFFFF), color-stop(108.25%, #F5F6F9)), #FFFFFF;
    background: linear-gradient(180deg, #FFFFFF 0%, #F5F6F9 108.25%), #FFFFFF;
    border: 1px solid #DFDEF1;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-radius: 0px 0px 5px 5px;
    padding: 21px 25px; }
    .scan_row .desc_wrap .data_block {
      font-weight: 500;
      line-height: 18px;
      font-size: 12px;
      color: #666666; }
    .scan_row .desc_wrap .field_title {
      font-weight: 600;
      line-height: 27px;
      font-size: 18px; }
    .scan_row .desc_wrap .scan_img {
      position: relative;
      border: 1px solid #DFDEF1;
      border-radius: 5px 5px 0px 0px;
      border-bottom: none; }
      .scan_row .desc_wrap .scan_img:before {
        position: absolute;
        content: '';
        background: -webkit-gradient(linear, left top, left bottom, color-stop(52.63%, rgba(255, 255, 255, 0)), color-stop(406.9%, rgba(43, 34, 79, 0.62)));
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 52.63%, rgba(43, 34, 79, 0.62) 406.9%);
        left: 0;
        right: 0;
        bottom: 0;
        height: 83px; }
      .scan_row .desc_wrap .scan_img img {
        width: 315px;
        height: 445px;
        -o-object-fit: cover;
           object-fit: cover; }

.error_page .block-system-main {
  max-width: none;
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  background: #457C00;
  background: url(../images/error.png); }

.error_page .error_content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.error_page .left_block {
  font-weight: 800;
  line-height: 219px;
  font-size: 144px;
  color: #fff; }

.error_page .right_block {
  padding-left: 30px;
  color: #fff; }
  .error_page .right_block .sub_title {
    font-weight: 700;
    line-height: 55px;
    font-size: 36px;
    padding-bottom: 15px; }
  .error_page .right_block a {
    color: #fff;
    text-decoration: underline; }
    .error_page .right_block a:hover {
      text-decoration: none; }

.scan_conteiner {
  font-size: 0;
  border-bottom: 1px solid #F0F0F6;
  padding-bottom: 15px; }

.scan_title {
  padding: 20px;
  font-size: 22px;
  font-weight: 600; }

.skan-type .block-system-main {
  -webkit-box-shadow: none;
          box-shadow: none; }

.reviews .header_contenc_page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 23px 40px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .reviews .header_contenc_page .link_heder_page a {
    -webkit-transition: 0.2s;
    transition: 0.2s;
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    font-family: "Montserrat";
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    text-align: center;
    font-weight: 500;
    border-radius: 5px;
    position: relative;
    z-index: 2;
    overflow: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    font-size: 14px;
    padding: 11px 20px;
    color: #fff;
    background: #457C00;
    border: 1px solid #457C00;
    padding: 0px 40px; }
    .reviews .header_contenc_page .link_heder_page a:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0px;
      right: 0;
      bottom: 0;
      z-index: -1;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      -webkit-transform: scaleY(0);
              transform: scaleY(0); }
    .reviews .header_contenc_page .link_heder_page a:hover:before {
      -webkit-transform: scale(2);
              transform: scale(2); }
    .reviews .header_contenc_page .link_heder_page a:before {
      background: #fff; }
    .reviews .header_contenc_page .link_heder_page a:hover {
      color: #457C00;
      border-color: #457C00; }
    .reviews .header_contenc_page .link_heder_page a:active {
      color: #294900;
      border-color: #294900;
      text-shadow: none; }

.row_reviews {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 40px;
  border-bottom: 1px solid #F0F0F6;
  cursor: pointer; }
  .row_reviews .avtor_inform {
    width: 270px; }
  .row_reviews .reviews_desc {
    padding: 30px;
    max-width: 1460px; }
  .row_reviews .block_name {
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 15px; }
  .row_reviews .blck_data {
    color: #666666;
    font-size: 12px;
    font-style: italic; }
  .row_reviews.admin_answer {
    background: #F2F3F9; }

.inform_for_user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.block-webform-electronic-reviews .from_action {
  text-align: center; }
  .block-webform-electronic-reviews .from_action .form-submit {
    width: 100%;
    margin: auto;
    max-width: 370px;
    font-size: 18px; }

.block_add_reviews {
  padding: 50px 0;
  background: #F2F3F9; }

.resul_line {
  width: 100%;
  font-size: 0;
  position: relative; }
  .resul_line .line_step {
    display: inline-block;
    width: 25%;
    height: 32px; }
    .resul_line .line_step:nth-of-type(1) {
      background: #407B1D;
      border-radius: 5px 0 0 5px; }
    .resul_line .line_step:nth-of-type(2) {
      background: #F6DE4A; }
    .resul_line .line_step:nth-of-type(3) {
      background: #FC7038; }
    .resul_line .line_step:nth-of-type(4) {
      border-radius: 0 5px 5px 0;
      background: #F6212B; }

.block_result_test .title {
  font-size: 22px;
  font-weight: 600;
  padding-bottom: 20px; }

.result_point {
  font-size: 14px;
  position: absolute;
  color: #F6DE4A;
  font-weight: 600;
  bottom: -40px; }
  .result_point:before {
    position: absolute;
    content: '';
    top: 0;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 10px solid #F6DE4A;
    left: 39%;
    top: -10px; }

.min_result_point {
  font-size: 14px;
  position: absolute;
  color: #D6D5D9;
  font-weight: 600;
  bottom: -60px;
  max-width: 130px;
  text-align: center; }
  .min_result_point:before {
    position: absolute;
    content: '';
    top: 0;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 10px solid #D6D5D9;
    left: 39%;
    top: -10px; }

.result_label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-bottom: 15px;
  font-weight: 500; }

.result_desc {
  padding-top: 60px; }

.second_menu {
  display: none;
  position: absolute;
  background: -webkit-gradient(linear, left top, right top, color-stop(146.15%, #CCCCCC), color-stop(34.41%, rgba(204, 204, 204, 0)));
  background: linear-gradient(90deg, #CCCCCC 146.15%, rgba(204, 204, 204, 0) 34.41%);
  width: 100%;
  background: #fff;
  left: 0;
  right: 0;
  margin: auto;
  top: 100%;
  z-index: 4;
  margin-top: -31px; }
  .second_menu .menu_item:hover .third_menu {
    display: block; }
  .second_menu li {
    list-style: none;
    padding: 4px 25px;
    width: 25%; }
  .second_menu .third_menu {
    display: none;
    position: absolute;
    left: 25%;
    width: 25%;
    top: 0;
    bottom: 0;
    margin: 0; }
    .second_menu .third_menu:before {
      position: absolute;
      content: '';
      width: 20px;
      background: -webkit-gradient(linear, left top, right top, color-stop(158.15%, #CCCCCC), color-stop(34.41%, rgba(204, 204, 204, 0)));
      opacity: 0.5;
      top: 0;
      bottom: 0;
      left: -20px; }
    .second_menu .third_menu li {
      width: 100%; }
    .second_menu .third_menu .menu_item:hover .fourth_menu {
      display: block; }
  .second_menu .fourth_menu,
  .second_menu .fifth_menu {
    display: none;
    position: absolute;
    left: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    margin: 0; }
    .second_menu .fourth_menu:before,
    .second_menu .fifth_menu:before {
      position: absolute;
      content: '';
      width: 20px;
      background: -webkit-gradient(linear, left top, right top, color-stop(158.15%, #CCCCCC), color-stop(34.41%, rgba(204, 204, 204, 0)));
      opacity: 0.5;
      top: 0;
      bottom: 0;
      left: -20px; }
  .second_menu .fourth_menu > .menu_item:hover .fifth_menu {
    display: block; }

.fff {
  background: -webkit-gradient(linear, left top, right top, color-stop(146.15%, #CCCCCC), color-stop(34.41%, rgba(204, 204, 204, 0)));
  background: linear-gradient(90deg, #CCCCCC 146.15%, rgba(204, 204, 204, 0) 34.41%);
  opacity: 0.5; }

.menu_list:hover .second_menu {
  display: block; }

.bg_menu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  overflow: hidden;
  position: fixed;
  background: #000;
  opacity: .6; }
  .bg_menu.active {
    display: block; }

.popap_wraper {
  display: none; }

.block-webform-electronic-reviews {
  max-width: 1170px; }
  .block-webform-electronic-reviews .inform_for_user .form_item {
    margin: 0; }

img {
  border: none;
  vertical-align: middle;
  max-width: 100%;
  height: auto; }

.history-type .midle_desc .left_block {
  max-width: 570px;
  width: 100%; }

.menu_page {
  margin: auto;
  padding: 30px 0;
  position: relative; }
  .menu_page .second_menu {
    padding-bottom: 30px; }
  .menu_page ul {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    position: static;
    margin: auto;
    text-align: center;
    min-height: auto;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .menu_page ul:before {
      content: none !important; }
    .menu_page ul li {
      width: auto;
      display: inline-table;
      margin: 0;
      padding: 0; }
      .menu_page ul li a {
        border: 1px solid #E7ECF1;
        text-decoration: none;
        margin-left: -1px;
        display: block;
        padding: 15px 25px;
        -webkit-transition: 0.2s;
        transition: 0.2s;
        background: linear-gradient(178.97deg, rgba(255, 255, 255, 0.55) 5.92%, rgba(241, 242, 249, 0) 103.49%), #F7F6FB; }
        .menu_page ul li a:hover {
          background: #457C00;
          color: #fff; }
        .menu_page ul li a.active {
          background: #457C00;
          color: #fff; }
      .menu_page ul li:first-child a {
        border-radius: 5px 0 0 5px; }
      .menu_page ul li:last-child a {
        border-radius: 0 5px 5px 0; }
  .menu_page.third_child {
    margin-bottom: 100px; }
  .menu_page.fourth_child {
    margin-bottom: 200px; }
  .menu_page .third_menu {
    top: 100%;
    width: 100%;
    margin: auto;
    left: 0;
    background: #F2F3F9;
    padding-top: 30px;
    padding-bottom: 30px; }
    .menu_page .third_menu li {
      width: auto; }
      .menu_page .third_menu li a {
        background: #FFF; }
  .menu_page .fourth_menu {
    top: 100%;
    width: 100%;
    left: 0;
    height: 100px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }

.tyr_wrap {
  height: 540px; }

.tyr_desc {
  padding-top: 20px; }

.specialists img {
  margin: auto; }

.our_specialists .slick-arrow {
  position: absolute;
  width: 42px;
  height: 42px;
  background: url(../images/Sharp.svg) no-repeat center;
  border: none;
  z-index: 2;
  font-size: 0;
  cursor: pointer;
  top: 50%;
  margin-top: -21px;
  left: 50%; }

.our_specialists .slick-next {
  right: -50px;
  left: auto; }

.our_specialists .slick-prev {
  left: -50px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.front_maps {
  position: relative; }
  .front_maps .wrap_desc_map {
    padding-top: 30px;
    text-align: center;
    color: #fff;
    position: absolute;
    max-width: 570px;
    width: 100%;
    background: url(../images/maps_im.png) no-repeat center center;
    background-size: cover;
    height: 240px;
    z-index: 2;
    left: 0;
    right: 0;
    margin: auto;
    top: 120px; }
  .front_maps .title {
    font-size: 32px;
    font-weight: 700;
    padding-bottom: 15px; }
  .front_maps .desc {
    padding-bottom: 25px;
    font-size: 18px; }
  .front_maps .maps_btn a {
    -webkit-transition: 0.2s;
    transition: 0.2s;
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    font-family: "Montserrat";
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    text-align: center;
    font-weight: 500;
    border-radius: 5px;
    position: relative;
    z-index: 2;
    overflow: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    font-size: 18px;
    padding: 12px 45px;
    background: #fff;
    color: #457C00;
    border: 1px solid #457C00;
    border: 1px solid #fff; }
    .front_maps .maps_btn a:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0px;
      right: 0;
      bottom: 0;
      z-index: -1;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      -webkit-transform: scaleY(0);
              transform: scaleY(0); }
    .front_maps .maps_btn a:hover:before {
      -webkit-transform: scale(2);
              transform: scale(2); }
    .front_maps .maps_btn a:before {
      background: #457C00; }
    .front_maps .maps_btn a:hover {
      color: #fff; }
    .front_maps .maps_btn a:active {
      color: #294900;
      border-color: #294900; }

.header_bottom .second_menu li {
  padding: 0; }
  .header_bottom .second_menu li a {
    padding: 10px 25px;
    display: block;
    width: 100%;
    text-decoration: none;
    position: relative;
    z-index: 2; }
  .header_bottom .second_menu li:hover > a {
    font-weight: 700;
    border-top: 1px solid #E7ECF1;
    border-bottom: 1px solid #E7ECF1; }
    .header_bottom .second_menu li:hover > a:before {
      position: absolute;
      content: '';
      right: 0;
      top: 0;
      width: 40px;
      bottom: 0;
      z-index: 2;
      background: #fff; }

.information_block .img_wrap,
.information_block .desc_wrap {
  width: 100%; }

.information_block .img_wrap {
  max-width: 460px; }

.header_wrapper .header_bottom .manin_menu .first_menu .menu_list {
  max-height: 34px; }

.main_conten_form .left_block,
.main_conten_form .right_block {
  width: 100%; }

.main_conten_form .left_block {
  max-width: 360px; }

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

.reviews_rew_title {
  text-align: center;
  /* padding: 10px 0; */
  padding-top: 20px;
  font-size: 18px;
  font-weight: 600; }

.career_row .left_block,
.career_row .right_block {
  width: 100%; }

.career_row .left_block {
  max-width: 400px; }

.error_page .block-system-main {
  margin: 0; }

.menu_list:hover .second_menu {
  -webkit-animation-name: example;
          animation-name: example;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s; }

.block-add-rewiew {
  max-width: 635px;
  margin: auto;
  position: relative;
  background: #fff;
  padding: 60px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  .block-add-rewiew .block_rew_wrap {
    width: 270px;
    min-height: 270px;
    border-radius: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background: #f2f3f9;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }

@-webkit-keyframes example {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes example {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.video_conteiner .desc_wrap {
  width: 100%; }

.video_wrap {
  height: 350px; }

.news_container.news_page .news_block {
  width: 25%;
  padding: 15px; }

.news_container.news_page {
  margin: 0 30px; }

.news_page .news_content {
  padding-top: 30px; }

.news_page .news_content .desc_news {
  text-align: justify; }

.news_page .news_content .title_news {
  font-size: 28px;
  padding-bottom: 10px; }

.news_page .news_content .img_wrap {
  float: left;
  padding-right: 30px;
  padding-bottom: 30px; }

.news_block .desc_wrap a {
  -webkit-transition: 0.2s;
  transition: 0.2s; }
  .news_block .desc_wrap a:hover {
    color: #457C00;
    text-decoration: none; }

.news_container .news_block .img_wrap a {
  position: relative; }
  .news_container .news_block .img_wrap a:before {
    position: absolute;
    content: '';
    width: 88px;
    height: 88px;
    background: #457C00 url(../images/news_arr.svg) no-repeat center center;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
            transform: translateY(-50%) translateX(-50%);
    -webkit-transition: 0.2s;
    transition: 0.2s;
    opacity: 0; }
  .news_container .news_block .img_wrap a:hover:before {
    opacity: 1; }

.error_page .block-system-main {
  min-height: calc(100vh - 318px); }

.doctor_preview .right_block {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  max-width: 49%; }

.registered_doctor_block .phone_block {
  width: 29%; }

.registered_doctor_block .other_block {
  width: 69%; }

@media screen and (max-width: 1800px) {
  .header_wrapper .header_bottom .manin_menu .first_menu .menu_list {
    margin: 0; }
  .midle_desc .left_block,
  .midle_desc .right_block {
    width: 100%; }
  .career_row .left_block,
  .career_row .right_block {
    width: 100%; }
  .news_container .news_block .img_wrap img {
    width: 100%; }
  .header_wrapper .container {
    padding: 0 10px; }
  .news_container {
    margin: 0 10px; }
  .header_wrapper .header_bottom .manin_menu .first_menu {
    max-width: 460px; }
  .middle .our_services .services_content {
    padding: 0 30px; }
  .middle .our_services .service .link a {
    font-size: 14px; }
  .services_content .image {
    height: 100%; }
  .middle .our_services .service img {
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover; }
  .our_specialists {
    padding-left: 60px;
    padding-right: 60px; }
  .specialists_content .description {
    line-height: normal; }
  .our_advantages {
    padding: 0 60px; }
  .block_reviews {
    padding: 70px 60px; }
  .footer .container {
    padding: 0 10px; }
  .specialists_content .description {
    padding: 0px 27px; }
  .middle .slider_front_wrapper .slick-arrow.slick-next {
    margin-right: -525px; }
  .middle .slider_front_wrapper .slick-arrow {
    margin-left: -525px; }
  .middle .slider_content_wrapper .iso_wrapper {
    bottom: 10px;
    right: 10px; }
  .doctors-list-block .doctors-list-row {
    width: 25%;
    text-align: left; }
  .doctor_view .doctors-list-block {
    margin: 0 10px; }
  .line_wrapper {
    padding: 20px 10px; }
  .left_block_inform {
    padding: 0 10px;
    width: 100%; }
  .detail_informations_wrapper {
    padding: 0 10px;
    padding-bottom: 40px; }
  .importantly_block {
    padding: 20px 0; }
  .doctors_conteiner .doctors_row {
    width: 25%; }
  .doctors_conteiner .doctors_row .row_title {
    padding: 25px 15px; }
  .tariff_informations_block {
    padding: 0 10px;
    padding-top: 30px; }
  .tariffs_price {
    padding: 0 10px; }
  .diagnostics_conteiner .diagnostics_row {
    width: 25%; }
  .information_slider .information_wrap {
    padding: 0 15px;
    padding-top: 136px; }
  .diagnostics-slider .slick-dots {
    left: 10px; }
  .couser_conteiner {
    margin: 0 10px; }
  .diagnostics_block_info {
    padding: 0 10px; }
  .block_desc {
    padding-left: 30px; }
  .survey_plan_block .container {
    margin: auto; }
  .doctor_view .doctors-list-block {
    text-align: center; }
  .online_page .online_page_container .online_test_row {
    width: 25%; }
  .block-system-main {
    margin: 0 60px;
    margin-bottom: 30px; }
  .galery_content {
    text-align: center; }
  .contacts_page .block-system-main {
    margin: 0 60px;
    margin-bottom: 60px; }
  .galery_content .galery_row {
    width: 33.33%;
    text-align: left; }
  .galery_prew .left_block {
    width: 100%; }
  .page-doctor .block-system-main {
    min-height: calc(100vh - 342px);
    margin: auto; }
  .standart_row img {
    height: 245px; } }

.galery_prew .left_block,
.galery_prew .right_block {
  width: 100%; }

.galery_prew .left_block {
  max-width: 400px; }

@media screen and (max-width: 1300px) {
  .header_wrapper .header_bottom .manin_menu .first_menu .menu_list .main_menu_link {
    padding: 8px 19px 8px 18px; }
  .galery_content .galery_row {
    border-bottom: none; }
  .header_wrapper .header_bottom .manin_menu .first_menu {
    max-width: 421px; }
  .middle .our_services .service .link a {
    line-height: normal; }
  .block_add_reviews {
    padding: 50px 10px; }
  .scan_row {
    width: 25%; } }

@media screen and (max-width: 1169px) {
  .header_wrapper .header_top .sign_up_btn {
    display: none; }
  .header_wrapper .header_bottom .manin_menu {
    display: none; }
  .header_wrapper .left_side {
    display: none; }
  .header_wrapper .header_top .logo {
    position: static;
    margin: auto; }
  .header_wrapper .container {
    height: auto; }
  .middle .slider_content_wrapper .button_wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .middle .slider_content_wrapper .button_wrap .btn_item {
      margin: 5px 0; }
  .middle .our_services .service {
    width: 50%; }
  .specialists_content .title_link {
    max-width: 270px;
    width: 100%; }
  .advantages_content .advantages {
    width: 50%; }
  .advantages_content .description {
    padding-left: 10px;
    padding-right: 10px; }
  .block_reviews .reviews_row {
    width: 100%;
    border-bottom: 1px solid #E7ECF1; }
  .menu_footer_wrap {
    display: none; }
  .footer .logo_footer {
    margin: auto; }
  .footer .container {
    display: block;
    text-align: center; }
  .footer .group_wrap {
    display: block; }
  .menu_page ul.menu-select {
    max-height: 50px;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: normal;
        -ms-flex-pack: normal;
            justify-content: normal;
    padding: 0 30px;
    -webkit-transition: 0.2s;
    transition: 0.2s; }
    .menu_page ul.menu-select.is-open {
      max-height: 999px; }
      .menu_page ul.menu-select.is-open .menu_item.active a:before {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg); }
  .menu-select .menu_item.active {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
    .menu-select .menu_item.active a {
      border-radius: 5px;
      position: relative;
      pointer-events: none; }
      .menu-select .menu_item.active a:before {
        position: absolute;
        content: '';
        right: 10px;
        top: 10px;
        width: 25px;
        height: 25px;
        background: url(../images/select_arrow.svg) no-repeat center center;
        -webkit-transition: 0.2s;
        transition: 0.2s; }
  .about-use-type .desc_page {
    text-align: justify;
    padding: 0 30px; }
  .about-use-type .add_img .item {
    width: 33%;
    padding: 15px; }
  .about-use-type .add_img {
    text-align: center;
    padding: 0 30px; }
  .phone_wrapper .row_phone {
    width: 33%; }
  .with_questions_block .field_first {
    margin: 30px; }
  .contacts_page .block-system-main {
    margin: auto;
    margin-bottom: 30px; }
  .diagnostics_conteiner .diagnostics_row {
    width: 33.33%; }
  .survey_plan_block .container .plan_row {
    width: 50%;
    padding-bottom: 15px; }
  .survey_plan_block .container {
    text-align: center; }
  .diagnostics_block_info {
    padding: 0 30px; }
  .diagnostics_block_info .container {
    padding: 0; }
  .survey_plan_block {
    padding: 0; }
  .doctors-list-block .doctors-list-row {
    width: 33%; }
  .doctor_preview .conteiner {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end; }
  .doctor_preview .btn_wrapper .btn_item {
    width: 100%; }
  .left_block_inform {
    padding-bottom: 12px; }
  .doctors_conteiner .doctors_row {
    width: 33.33%; }
  .menu_page .fourth_menu {
    height: auto; }
    .menu_page .fourth_menu li {
      width: 100%; }
  .menu_page .third_menu {
    background: #fff; }
  .menu_page ul.menu-select {
    margin-bottom: 10px; }
  .menu_page .third_menu li a {
    background: linear-gradient(178.97deg, rgba(255, 255, 255, 0.55) 5.92%, rgba(241, 242, 249, 0) 103.49%), #F7F6FB; }
  .block_add_reviews {
    display: none; }
  .information_block {
    display: block; }
  .information_block .img_wrap {
    float: left; }
  .add_information {
    clear: both; }
  .midle_galery {
    padding: 30px; }
  .slide_min .item img {
    margin: auto; }
  .slide_min {
    margin-left: 75px;
    margin-right: 75px; }
  .footer .footer_top {
    height: auto; }
  .footer .logo_footer {
    padding: 10px 0; }
  .midle_desc {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
  .online_page .online_page_container .online_test_row {
    width: 33%; }
  .error_page .error_content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
  .header_wrapper .header_top .logo {
    max-width: 170px;
    padding: 5px 10px; }
  .header_wrapper .header_bottom .manin_menu {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .header_wrapper .header_bottom .manin_menu .first_menu {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .header_wrapper .header_bottom .manin_menu .first_menu .menu_list.expanded .main_menu_link {
    width: 100%; }
  .header_wrapper .header_bottom .manin_menu .first_menu .menu_list {
    width: 100%; }
  .header_wrapper .header_bottom .manin_menu .first_menu {
    max-width: none; }
  .header_wrapper .header_bottom .manin_menu .first_menu {
    text-align: left; }
  .header_wrapper .header_bottom .manin_menu {
    position: absolute;
    left: 10px;
    right: 10px;
    background: #ffffff;
    width: auto;
    z-index: 5;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    -webkit-transform: translateX(-120%);
            transform: translateX(-120%);
    display: block; }
  .header_wrapper .header_bottom .manin_menu.open {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-box-shadow: 0px 25px 75px rgba(81, 107, 115, 0.15);
            box-shadow: 0px 25px 75px rgba(81, 107, 115, 0.15); }
  .btn_mobile {
    background: rebeccapurple;
    position: absolute;
    width: 50px;
    height: 50px;
    top: 0; }
  .header_wrapper .header_bottom .manin_menu .first_menu .menu_list {
    position: relative; }
  .arrow_menu {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 10px;
    top: 10px;
    background: url(../images/chevorn-down.svg) no-repeat center center;
    right: 15px;
    margin-top: -6px;
    z-index: 5;
    -webkit-transition: 0.2s;
    transition: 0.2s; }
    .arrow_menu.active {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
  .header_wrapper .header_bottom .manin_menu .first_menu .menu_list.expanded .main_menu_link {
    z-index: 3; }
  .header_wrapper .second_menu {
    /* display: block; */
    margin: 0;
    position: relative; }
  .header_wrapper .second_menu .menu_item:hover .third_menu {
    display: none; }
  .header_wrapper .second_menu .third_menu,
  .header_wrapper .fourth_menu {
    position: relative;
    /* display: block; */
    width: 100%; }
  .header_wrapper .menu_list:hover .second_menu {
    display: none; }
  .header_wrapper .header_bottom .manin_menu .first_menu .menu_list {
    max-height: none;
    height: auto !important; }
  .header_wrapper .header_bottom .manin_menu .first_menu {
    height: auto; }
  .header_wrapper .menu_list:hover .second_menu {
    -webkit-animation-name: unset;
            animation-name: unset; }
  .header_wrapper .bg_menu {
    display: none !important; }
  .header_wrapper .second_menu li {
    width: 100%; }
  .header_wrapper .second_menu ul {
    height: auto !important; }
  .header_wrapper .second_menu .third_menu {
    left: 0; }
  .header_wrapper .second_menu .third_menu .menu_item .fourth_menu {
    position: relative;
    left: 0; }
  .mobile-btn--menu {
    width: 31px;
    height: 43px;
    margin-left: 30px;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    z-index: 5;
    position: absolute;
    top: 30px; }
  .mobile-btn--menu span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #457C00;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    transition: .25s ease-in-out; }
  .mobile-btn--menu span:nth-child(1) {
    top: 0px; }
  .mobile-btn--menu span:nth-child(2) {
    top: 11px; }
  .mobile-btn--menu span:nth-child(3) {
    top: 23px; }
  .mobile-btn--menu.open span:nth-child(1) {
    top: 18px;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg); }
  .mobile-btn--menu.open span:nth-child(2) {
    opacity: 0;
    left: -60px; }
  .mobile-btn--menu.open span:nth-child(3) {
    top: 18px;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg); }
  .header_wrapper .header_bottom .manin_menu .first_menu .menu_list.expanded .main_menu_link::after {
    content: none; }
  .header_wrapper .second_menu li {
    position: relative; }
  .scan_row {
    width: 33.3%; }
  .scan_row .desc_wrap .field_title {
    font-size: 16px; }
  .standart_row {
    width: 33%; }
  .video_conteiner .video_row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
  .video_wrap {
    margin: auto;
    padding-bottom: 30px; }
  .video_conteiner .desc_wrap {
    padding-left: 0; }
  .header_wrapper .search_block_wrap .search_btn {
    position: absolute;
    right: 10px;
    margin-right: 0; }
  .news_container.news_page .news_block {
    width: 33%; }
  .news_container .news_block .desc_wrap {
    font-size: 14px; }
  .doctor_preview .conteiner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
  .doctor_preview {
    min-height: calc(100vh - 342px); } }

@media screen and (max-width: 767px) {
  .middle .our_services .service {
    width: 100%;
    margin-bottom: 10px; }
  .advantages_content .advantages {
    width: 100%;
    border-bottom: 1px solid #E7ECF1; }
  .footer .address {
    display: none; }
  .footer .phone_header {
    display: none; }
  .footer .container {
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-align: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse; }
  .footer .sign_up_btn {
    margin: auto;
    width: 100%; }
  .block_reviews {
    padding: 30px; }
  .block_check_yourself .conteiner {
    padding: 0 30px; }
  .block_check_yourself .title_wrpa {
    font-size: 28px; }
  .resul_line {
    padding-top: 60px; }
  .min_result_point {
    bottom: auto;
    top: 0; }
  .min_result_point:before {
    bottom: -12px;
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
    top: auto; }
  .about_page_conteiner .img_wrap {
    float: none;
    text-align: center;
    padding-right: 0; }
  .news_container .news_block {
    width: 100%;
    padding: 15px; }
  .main_conten_form .from_action .form_submit {
    width: 100%; }
  .menu_page ul.menu-select li {
    width: 100%; }
  .scan_conteiner {
    text-align: center; }
  .our_advantages {
    padding: 0 30px; }
  .block_title {
    font-size: 22px; }
  .front_maps .maps_btn a {
    font-size: 14px; }
  .front_maps .wrap_desc_map {
    max-width: 270px;
    padding-top: 60px; }
  .front_maps .title {
    font-size: 22px; }
  .our_specialists {
    padding-left: 30px;
    padding-right: 30px; }
    .our_specialists .slick-dots {
      left: 0;
      right: 0;
      margin: auto;
      /* text-align: center; */
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      bottom: -37px;
      text-align: center; }
  .our_specialists .slick-prev {
    left: 0; }
  .our_specialists .slick-arrow {
    top: auto;
    bottom: -50px; }
  .our_specialists .slick-next {
    right: 0; }
  .specialists_content .title_link {
    padding: 10px; }
  .middle .slider_content_wrapper .container {
    padding-top: 60px; }
  .middle .slider_content_wrapper .title {
    font-size: 22px; }
  .middle .slider_content_wrapper .button_wrap .btn_item {
    width: 270px; }
    .middle .slider_content_wrapper .button_wrap .btn_item a {
      width: 100%; }
  .middle .slider_front_wrapper {
    height: 505px; }
    .middle .slider_front_wrapper .slick-dots {
      bottom: 15px; }
  .middle .slider_front_wrapper .slide {
    height: 505px; }
  .middle .slider_content_wrapper .services_btn a,
  .middle .slider_content_wrapper .doctor_btn a,
  .middle .slider_content_wrapper .order_call_btn {
    padding: 10px 0;
    line-height: normal; }
  .middle .slider_content_wrapper .text_after .first_text {
    font-size: 22px;
    line-height: normal; }
  .iso_img {
    max-width: 60px; }
  .middle .slider_content_wrapper .iso_wrapper .iso_description {
    bottom: 65px; }
  .middle .slider_content_wrapper .text_after {
    padding-top: 10px; }
  .block-system-main {
    margin: 0 auto;
    margin-bottom: 30px; }
  .tyr_desc {
    padding: 0 10px; }
  .tyr_wrap {
    height: 240px; }
  .about-use-type .add_img .item {
    width: 100%; }
  .about_use_conteiner {
    padding-top: 0; }
    .about_use_conteiner .img_wrap {
      height: 220px; }
      .about_use_conteiner .img_wrap img {
        height: 100%;
        -o-object-fit: cover;
           object-fit: cover; }
  .career_row .right_block {
    padding-left: 0; }
  .career_row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    .career_row .left_block {
      padding-bottom: 10px; }
  .phone_wrapper {
    text-align: center; }
  .phone_wrapper .row_phone {
    width: 100%;
    text-align: left; }
  .contacts_page .header_contenc_page {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
  .header_contenc_page {
    font-size: 18px; }
  .with_questions_block .row_with_questions {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
  .with_questions_block .field_first {
    width: auto; }
  .with_questions_block .field_second,
  .with_questions_block .field_first {
    margin: 10px 30px;
    width: auto; }
  .with_questions_block .field_first {
    font-size: 14px; }
  .diagnostics_conteiner .diagnostics_row {
    width: 100%; }
  .survey_plan_block .container .plan_row {
    width: 100%;
    padding-bottom: 15px; }
  .diagnostics_block_info .container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    .diagnostics_block_info .container .block_desc {
      padding-top: 20px;
      padding-left: 0; }
  .diagnostics_block_info .btn_linc {
    text-align: center; }
  .couser_conteiner {
    padding-top: 15px; }
  .doctors-list-block .doctors-list-row {
    width: 50%; }
  .doctor_view .doctors-list-block {
    text-align: center; }
  .information_slider .information_wrap {
    padding-top: 60px;
    text-align: center; }
    .information_slider .information_wrap .slide_lin a {
      width: 100%; }
  .diagnostics-slider .slick-dots {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  .slide_img img {
    height: 516px; }
  .doctor_preview .conteiner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
  .doctor_preview .btn_wrapper {
    display: none; }
  .doctor_preview .title_node {
    line-height: normal;
    font-size: 18px;
    padding-top: 40px; }
  .line_wrapper .page_title {
    font-size: 32px; }
  .detail_informations_wrapper {
    padding: 0; }
  .detail_informations_block {
    padding-top: 0; }
  .experience_informations_block .field_item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
  .experience_informations_block .field_item .experience_first_item {
    padding-bottom: 20px; }
  .experience_informations_block .field_item .experience_first_item {
    max-width: 276px;
    width: 100%;
    margin: 0; }
  .informations_content {
    padding: 33px 30px; }
  .doctors_conteiner .doctors_row {
    width: auto; }
  .doctors_conteiner {
    text-align: center; }
  .doctors-list-block {
    text-align: center; }
  .reviews .header_contenc_page .link_heder_page {
    display: none; }
  .row_reviews {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start; }
  .row_reviews .reviews_desc {
    padding: 30px 0; }
  .row_reviews {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
  .reviews .header_contenc_page {
    padding: 0 15px; }
  .row_reviews .reviews_desc {
    padding-bottom: 0px; }
  .desc_wrap {
    text-align: justify; }
  .galery_prew .left_block {
    margin: auto; }
  .galery_prew {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
  .galery_content .galery_row {
    width: 50%;
    border-bottom: none; }
  .slide_big .slick-arrow {
    bottom: -60px;
    width: 44px;
    height: 44px; }
  .slide_min .item img {
    width: 44px;
    height: 44px; }
  .slide_min {
    margin-left: 45px;
    margin-right: 45px; }
  .slide_big .item img {
    height: 205px; }
  .midle_galery {
    -webkit-box-shadow: none;
            box-shadow: none; }
  .block_contact_content .title_contact:before {
    content: none; }
  .block_contact_content .item_contect:nth-of-type(odd) .title_contact:after {
    content: none; }
  .block_contact_content .item_contect {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
  .block_contact_content .item_contect:nth-of-type(odd) {
    background: none;
    margin-bottom: 5px; }
  .block_contact_content .title_contact {
    max-width: none;
    background: #F9F8FC;
    padding: 20px; }
  .block_contact_content .title {
    background: #F0F1F6; }
  .goal_block .goal_row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 15px 0; }
  .goal_block .goal_row:before {
    content: none; }
  .goal_block .icone_wrap {
    margin: auto;
    margin-bottom: 20px; }
  .goal_desc {
    text-align: center; }
    .goal_desc .title {
      font-weight: 600; }
  .goal_block .goal_desc {
    padding: 0 30px; }
  .description_block {
    text-align: justify; }
    .description_block .description_block .first_desc,
    .description_block .second_desc {
      padding: 10px 0; }
  .main_conten_form {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
  .main_conten_form .left_block {
    margin: auto; }
  .right_block .from_action {
    text-align: center; }
    .right_block .from_action input[type="submit"] {
      width: 100%;
      max-width: 270px; }
  .main_conten_form .question_block {
    font-size: 18px; }
  .main_conten_form {
    padding: 30px 0; }
  .main_conten_form .left_block,
  .main_conten_form .right_block {
    padding: 10px 0; }
  .online_test_content {
    padding-top: 0; }
  .online_page .online_page_container .online_test_row {
    width: 50%; }
  .result_desc {
    text-align: justify; }
  .scan_row {
    width: 50%; }
  .standart_row {
    width: 50%; }
  .standart_row img {
    height: auto; }
  .tariff_informations_block .img_item {
    float: none;
    text-align: center;
    padding-right: 0; }
  .desc_pricilage {
    display: none; }
  .tariff_informations_block {
    padding: 0 30px;
    padding-top: 30px; }
  .tariff_title {
    padding: 22px 20px; }
  .list_price .item {
    padding: 18px 20px 18px 20px; }
  .document_wrapper .doc_linc {
    border-radius: 0;
    font-size: 18px; }
  .document_wrapper {
    padding-bottom: 0; }
  .header_contenc_page {
    padding: 20px 30px; }
  .video_conteiner {
    padding: 0 30px; }
  .video_row .desc_wrap .field_title {
    text-align: left; }
  .header_wrapper .header_top .logo {
    max-width: 170px;
    padding: 5px 40px; }
    .header_wrapper .header_top .logo a {
      width: 90px; }
  .news_container.news_page .news_block {
    width: 50%; }
  .mobile-btn--menu {
    top: 15px;
    margin-left: 15px; }
  .news_page .news_content .img_wrap {
    float: none;
    padding-right: 0;
    text-align: center; }
  .news_page .news_content {
    padding: 0 30px;
    padding-top: 30px; }
  .news_page .news_content .title_news {
    font-size: 22px; }
  .menu_page ul.third_menu.menu-select li a {
    padding: 5px 25px; } }

@media screen and (max-width: 600px) {
  .couser_conteiner .couser_row {
    width: 50%; }
  .couser_conteiner {
    text-align: center; }
  .specialists_content .description {
    display: none; }
  .prev_glalery_inform_block .right_block {
    padding-left: 0; } }

@media screen and (max-width: 414px) {
  .couser_conteiner .couser_row {
    width: 100%; }
  .video_wrap {
    height: 250px; }
  .news_container.news_page {
    margin: 0 15px; }
  .scan_row {
    width: auto; }
  .news_container.news_page .news_block {
    width: auto; }
  input[type="text"],
  input[type="tel"],
  input[type="email"] {
    width: 100%; }
  .block-webform .from_action {
    max-width: 340px;
    margin: auto 40px; }
  .standart_row {
    width: auto; }
  .doctors-list-block .doctors-list-row {
    width: auto; }
  .galery_content .galery_row {
    width: 100%;
    padding: 15px 15px; }
  .online_page .online_page_container .online_test_row {
    width: auto; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.lg-sub-html, .lg-toolbar {
  background-color: rgba(0, 0, 0, 0.45); }

@font-face {
  font-family: lg;
  src: url(../fonts/lg.eot?n1z373);
  src: url(../fonts/lg.eot?#iefixn1z373) format("embedded-opentype"), url(../fonts/lg.woff?n1z373) format("woff"), url(../fonts/lg.ttf?n1z373) format("truetype"), url(../fonts/lg.svg?n1z373#lg) format("svg");
  font-weight: 400;
  font-style: normal; }

.lg-icon {
  font-family: lg;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.lg-actions .lg-next, .lg-actions .lg-prev {
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 2px;
  color: #999;
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-top: -10px;
  padding: 8px 10px 9px;
  position: absolute;
  top: 50%;
  z-index: 1080;
  border: none;
  outline: 0; }

.lg-actions .lg-next.disabled, .lg-actions .lg-prev.disabled {
  pointer-events: none;
  opacity: .5; }

.lg-actions .lg-next:hover, .lg-actions .lg-prev:hover {
  color: #FFF; }

.lg-actions .lg-next {
  right: 20px; }

.lg-actions .lg-next:before {
  content: "\e095"; }

.lg-actions .lg-prev {
  left: 20px; }

.lg-actions .lg-prev:after {
  content: "\e094"; }

@-webkit-keyframes lg-right-end {
  0%, 100% {
    left: 0; }
  50% {
    left: -30px; } }

@keyframes lg-right-end {
  0%, 100% {
    left: 0; }
  50% {
    left: -30px; } }

@-webkit-keyframes lg-left-end {
  0%, 100% {
    left: 0; }
  50% {
    left: 30px; } }

@keyframes lg-left-end {
  0%, 100% {
    left: 0; }
  50% {
    left: 30px; } }

.lg-outer.lg-right-end .lg-object {
  -webkit-animation: lg-right-end .3s;
  animation: lg-right-end .3s;
  position: relative; }

.lg-outer.lg-left-end .lg-object {
  -webkit-animation: lg-left-end .3s;
  animation: lg-left-end .3s;
  position: relative; }

.lg-toolbar {
  z-index: 1082;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }

.lg-toolbar .lg-icon {
  color: #999;
  cursor: pointer;
  float: right;
  font-size: 24px;
  height: 47px;
  line-height: 27px;
  padding: 10px 0;
  text-align: center;
  width: 50px;
  text-decoration: none !important;
  outline: 0;
  -webkit-transition: color .2s linear;
  transition: color .2s linear; }

.lg-toolbar .lg-icon:hover {
  color: #FFF; }

.lg-toolbar .lg-close:after {
  content: "\e070"; }

.lg-toolbar .lg-download:after {
  content: "\e0f2"; }

.lg-sub-html {
  bottom: 0;
  color: #EEE;
  font-size: 16px;
  left: 0;
  padding: 10px 40px;
  position: fixed;
  right: 0;
  text-align: center;
  z-index: 1080; }

.lg-sub-html h4 {
  margin: 0;
  font-size: 13px;
  font-weight: 700; }

.lg-sub-html p {
  font-size: 12px;
  margin: 5px 0 0; }

#lg-counter {
  color: #999;
  display: inline-block;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 12px;
  vertical-align: middle; }

.lg-next, .lg-prev, .lg-toolbar {
  opacity: 1;
  -webkit-transition: -webkit-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -webkit-transition: opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear, -webkit-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear, -webkit-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  transition: transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear, -webkit-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s; }

.lg-hide-items .lg-prev {
  opacity: 0;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0); }

.lg-hide-items .lg-next {
  opacity: 0;
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0); }

.lg-hide-items .lg-toolbar {
  opacity: 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0); }

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-object {
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
  opacity: 0;
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -webkit-transition: opacity 250ms cubic-bezier(0, 0, 0.25, 1), -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s !important;
  transition: opacity 250ms cubic-bezier(0, 0, 0.25, 1), -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s !important;
  transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1), -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s !important;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%; }

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item.lg-complete .lg-object {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1; }

.lg-outer .lg-thumb-outer {
  background-color: #0D0A0A;
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 1080;
  max-height: 350px;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s; }

.lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab; }

.lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing; }

.lg-outer .lg-thumb-outer.lg-dragging .lg-thumb {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important; }

.lg-outer.lg-thumb-open .lg-thumb-outer {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.lg-outer .lg-thumb {
  padding: 10px 0;
  height: 100%;
  margin-bottom: -5px; }

.lg-outer .lg-thumb-item {
  cursor: pointer;
  float: left;
  overflow: hidden;
  height: 100%;
  border: 2px solid #FFF;
  border-radius: 4px;
  margin-bottom: 5px; }

@media (min-width: 1025px) {
  .lg-outer .lg-thumb-item {
    -webkit-transition: border-color .25s ease;
    transition: border-color .25s ease; } }

.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
  border-color: #a90707; }

.lg-outer .lg-thumb-item img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover; }

.lg-outer.lg-has-thumb .lg-item {
  padding-bottom: 120px; }

.lg-outer.lg-can-toggle .lg-item {
  padding-bottom: 0; }

.lg-outer.lg-pull-caption-up .lg-sub-html {
  -webkit-transition: bottom .25s ease;
  transition: bottom .25s ease; }

.lg-outer.lg-pull-caption-up.lg-thumb-open .lg-sub-html {
  bottom: 100px; }

.lg-outer .lg-toogle-thumb {
  background-color: #0D0A0A;
  border-radius: 2px 2px 0 0;
  color: #999;
  cursor: pointer;
  font-size: 24px;
  height: 39px;
  line-height: 27px;
  padding: 5px 0;
  position: absolute;
  right: 20px;
  text-align: center;
  top: -39px;
  width: 50px; }

.lg-outer .lg-toogle-thumb:hover, .lg-outer.lg-dropdown-active #lg-share {
  color: #FFF; }

.lg-outer .lg-toogle-thumb:after {
  content: "\e1ff"; }

.lg-outer .lg-video-cont {
  display: inline-block;
  vertical-align: middle;
  max-width: 1140px;
  max-height: 100%;
  width: 100%;
  padding: 0 5px; }

.lg-outer .lg-video {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative; }

.lg-outer .lg-video .lg-object {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important; }

.lg-outer .lg-video .lg-video-play {
  width: 84px;
  height: 59px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -42px;
  margin-top: -30px;
  z-index: 1080;
  cursor: pointer; }

.lg-outer .lg-has-iframe .lg-video {
  -webkit-overflow-scrolling: touch;
  overflow: auto; }

.lg-outer .lg-has-vimeo .lg-video-play {
  background: url(../img/vimeo-play.png) no-repeat; }

.lg-outer .lg-has-vimeo:hover .lg-video-play {
  background: url(../img/vimeo-play.png) 0 -58px no-repeat; }

.lg-outer .lg-has-html5 .lg-video-play {
  background: url(../img/video-play.png) no-repeat;
  height: 64px;
  margin-left: -32px;
  margin-top: -32px;
  width: 64px;
  opacity: .8; }

.lg-outer .lg-has-html5:hover .lg-video-play {
  opacity: 1; }

.lg-outer .lg-has-youtube .lg-video-play {
  background: url(../img/youtube-play.png) no-repeat; }

.lg-outer .lg-has-youtube:hover .lg-video-play {
  background: url(../img/youtube-play.png) 0 -60px no-repeat; }

.lg-outer .lg-video-object {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0; }

.lg-outer .lg-has-video .lg-video-object {
  visibility: hidden; }

.lg-outer .lg-has-video.lg-video-playing .lg-object, .lg-outer .lg-has-video.lg-video-playing .lg-video-play {
  display: none; }

.lg-outer .lg-has-video.lg-video-playing .lg-video-object {
  visibility: visible; }

.lg-progress-bar {
  background-color: #333;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1083;
  opacity: 0;
  -webkit-transition: opacity 80ms ease 0s;
  transition: opacity 80ms ease 0s; }

.lg-progress-bar .lg-progress {
  background-color: #a90707;
  height: 5px;
  width: 0; }

.lg-progress-bar.lg-start .lg-progress {
  width: 100%; }

.lg-show-autoplay .lg-progress-bar {
  opacity: 1; }

.lg-autoplay-button:after {
  content: "\e01d"; }

.lg-show-autoplay .lg-autoplay-button:after {
  content: "\e01a"; }

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image, .lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition-duration: 0s;
  transition-duration: 0s; }

.lg-outer.lg-use-transition-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; }

.lg-outer.lg-use-left-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; }

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -webkit-transition: opacity 0.15s, -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s !important;
  transition: opacity 0.15s, -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s !important;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s, -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s !important;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

#lg-zoom-in:after {
  content: "\e311"; }

#lg-actual-size {
  font-size: 20px; }

#lg-actual-size:after {
  content: "\e033"; }

#lg-zoom-out {
  opacity: .5;
  pointer-events: none; }

#lg-zoom-out:after {
  content: "\e312"; }

.lg-zoomed #lg-zoom-out {
  opacity: 1;
  pointer-events: auto; }

.lg-outer .lg-pager-outer {
  bottom: 60px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 1080;
  height: 10px; }

.lg-outer .lg-pager-outer.lg-pager-hover .lg-pager-cont {
  overflow: visible; }

.lg-outer .lg-pager-cont {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  margin: 0 5px; }

.lg-outer .lg-pager-cont:hover .lg-pager-thumb-cont {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.lg-outer .lg-pager-cont.lg-pager-active .lg-pager {
  -webkit-box-shadow: 0 0 0 2px #fff inset;
          box-shadow: 0 0 0 2px #fff inset; }

.lg-outer .lg-pager-thumb-cont {
  background-color: #fff;
  color: #FFF;
  bottom: 100%;
  height: 83px;
  left: 0;
  margin-bottom: 20px;
  margin-left: -60px;
  opacity: 0;
  padding: 5px;
  position: absolute;
  width: 120px;
  border-radius: 3px;
  -webkit-transition: opacity .15s ease 0s,-webkit-transform .15s ease 0s;
  transition: opacity .15s ease 0s,-webkit-transform .15s ease 0s;
  transition: opacity .15s ease 0s,transform .15s ease 0s;
  transition: opacity .15s ease 0s,transform .15s ease 0s,-webkit-transform .15s ease 0s;
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0); }

.lg-outer .lg-pager-thumb-cont img {
  width: 100%;
  height: 100%; }

.lg-outer .lg-pager {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.7) inset;
          box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.7) inset;
  display: block;
  height: 12px;
  -webkit-transition: box-shadow .3s ease 0s;
  -webkit-transition: -webkit-box-shadow .3s ease 0s;
  transition: -webkit-box-shadow .3s ease 0s;
  transition: box-shadow .3s ease 0s;
  transition: box-shadow .3s ease 0s, -webkit-box-shadow .3s ease 0s;
  width: 12px; }

.lg-outer .lg-pager:focus, .lg-outer .lg-pager:hover {
  -webkit-box-shadow: 0 0 0 8px #fff inset;
          box-shadow: 0 0 0 8px #fff inset; }

.lg-outer .lg-caret {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px dashed;
  bottom: -10px;
  display: inline-block;
  height: 0;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  vertical-align: middle;
  width: 0; }

.lg-fullscreen:after {
  content: "\e20c"; }

.lg-fullscreen-on .lg-fullscreen:after {
  content: "\e20d"; }

.lg-outer #lg-dropdown-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  bottom: 0;
  cursor: default;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1081;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility 0s linear .18s,opacity .18s linear 0s;
  transition: visibility 0s linear .18s,opacity .18s linear 0s; }

.lg-outer.lg-dropdown-active #lg-dropdown-overlay, .lg-outer.lg-dropdown-active .lg-dropdown {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
  visibility: visible; }

.lg-outer .lg-dropdown {
  background-color: #fff;
  border-radius: 2px;
  font-size: 14px;
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
  position: absolute;
  right: 0;
  text-align: left;
  top: 50px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
  -webkit-transition: -webkit-transform .18s linear 0s,visibility 0s linear .5s,opacity .18s linear 0s;
  -webkit-transition: visibility 0s linear .5s,opacity .18s linear 0s,-webkit-transform .18s linear 0s;
  transition: visibility 0s linear .5s,opacity .18s linear 0s,-webkit-transform .18s linear 0s;
  transition: transform .18s linear 0s,visibility 0s linear .5s,opacity .18s linear 0s;
  transition: transform .18s linear 0s,visibility 0s linear .5s,opacity .18s linear 0s,-webkit-transform .18s linear 0s; }

.lg-outer .lg-dropdown:after {
  content: "";
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  border: 8px solid transparent;
  border-bottom-color: #FFF;
  right: 16px;
  top: -16px; }

.lg-outer .lg-dropdown > li:last-child {
  margin-bottom: 0; }

.lg-outer .lg-dropdown > li:hover .lg-icon, .lg-outer .lg-dropdown > li:hover a {
  color: #333; }

.lg-outer .lg-dropdown a {
  color: #333;
  display: block;
  white-space: pre;
  padding: 4px 12px;
  font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 12px; }

.lg-outer .lg-dropdown a:hover {
  background-color: rgba(0, 0, 0, 0.07); }

.lg-outer .lg-dropdown .lg-dropdown-text {
  display: inline-block;
  line-height: 1;
  margin-top: -3px;
  vertical-align: middle; }

.lg-outer .lg-dropdown .lg-icon {
  color: #333;
  display: inline-block;
  float: none;
  font-size: 20px;
  height: auto;
  line-height: 1;
  margin-right: 8px;
  padding: 0;
  vertical-align: middle;
  width: auto; }

.lg-outer, .lg-outer .lg, .lg-outer .lg-inner {
  width: 100%;
  height: 100%; }

.lg-outer #lg-share {
  position: relative; }

.lg-outer #lg-share:after {
  content: "\e80d"; }

.lg-outer #lg-share-facebook .lg-icon {
  color: #3b5998; }

.lg-outer #lg-share-facebook .lg-icon:after {
  content: "\e901"; }

.lg-outer #lg-share-twitter .lg-icon {
  color: #00aced; }

.lg-outer #lg-share-twitter .lg-icon:after {
  content: "\e904"; }

.lg-outer #lg-share-googleplus .lg-icon {
  color: #dd4b39; }

.lg-outer #lg-share-googleplus .lg-icon:after {
  content: "\e902"; }

.lg-outer #lg-share-pinterest .lg-icon {
  color: #cb2027; }

.lg-outer #lg-share-pinterest .lg-icon:after {
  content: "\e903"; }

.lg-group:after {
  content: "";
  display: table;
  clear: both; }

.lg-outer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  text-align: left;
  opacity: 0;
  -webkit-transition: opacity .15s ease 0s;
  transition: opacity .15s ease 0s; }

.lg-outer * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.lg-outer.lg-visible {
  opacity: 1; }

.lg-outer.lg-css3 .lg-item.lg-current, .lg-outer.lg-css3 .lg-item.lg-next-slide, .lg-outer.lg-css3 .lg-item.lg-prev-slide {
  -webkit-transition-duration: inherit !important;
  transition-duration: inherit !important;
  -webkit-transition-timing-function: inherit !important;
  transition-timing-function: inherit !important; }

.lg-outer.lg-css3.lg-dragging .lg-item.lg-current, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
  opacity: 1; }

.lg-outer.lg-grab img.lg-object {
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab; }

.lg-outer.lg-grabbing img.lg-object {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing; }

.lg-outer .lg {
  position: relative;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  max-height: 100%; }

.lg-outer .lg-inner {
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap; }

.lg-outer .lg-item {
  background: url(../img/loading.gif) center center no-repeat;
  display: none !important; }

.lg-outer.lg-css .lg-current, .lg-outer.lg-css3 .lg-current, .lg-outer.lg-css3 .lg-next-slide, .lg-outer.lg-css3 .lg-prev-slide {
  display: inline-block !important; }

.lg-outer .lg-img-wrap, .lg-outer .lg-item {
  display: inline-block;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%; }

.lg-outer .lg-img-wrap:before, .lg-outer .lg-item:before {
  content: "";
  display: inline-block;
  height: 50%;
  width: 1px;
  margin-right: -1px; }

.lg-outer .lg-img-wrap {
  position: absolute;
  padding: 0 5px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0; }

.lg-outer .lg-item.lg-complete {
  background-image: none; }

.lg-outer .lg-item.lg-current {
  z-index: 1060; }

.lg-outer .lg-image {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
  width: auto !important;
  height: auto !important; }

.lg-outer.lg-show-after-load .lg-item .lg-object, .lg-outer.lg-show-after-load .lg-item .lg-video-play {
  opacity: 0;
  -webkit-transition: opacity .15s ease 0s;
  transition: opacity .15s ease 0s; }

.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object, .lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play {
  opacity: 1; }

.lg-outer .lg-empty-html, .lg-outer.lg-hide-download #lg-download {
  display: none; }

.lg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0;
  -webkit-transition: opacity .15s ease 0s;
  transition: opacity .15s ease 0s; }

.lg-backdrop.in {
  opacity: 1; }

.lg-css3.lg-no-trans .lg-current, .lg-css3.lg-no-trans .lg-next-slide, .lg-css3.lg-no-trans .lg-prev-slide {
  -webkit-transition: none 0s ease 0s !important;
  transition: none 0s ease 0s !important; }

.lg-css3.lg-use-css3 .lg-item, .lg-css3.lg-use-left .lg-item {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.lg-css3.lg-fade .lg-item {
  opacity: 0; }

.lg-css3.lg-fade .lg-item.lg-current {
  opacity: 1; }

.lg-css3.lg-fade .lg-item.lg-current, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-prev-slide {
  -webkit-transition: opacity .1s ease 0s;
  transition: opacity .1s ease 0s; }

.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0; }

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0); }

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0); }

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1; }

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -webkit-transition: opacity 0.1s ease 0s, -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: opacity 0.1s ease 0s, -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s, -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s; }

.lg-css3.lg-slide.lg-use-left .lg-item {
  opacity: 0;
  position: absolute;
  left: 0; }

.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
  left: -100%; }

.lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide {
  left: 100%; }

.lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
  left: 0;
  opacity: 1; }

.lg-css3.lg-slide.lg-use-left .lg-item.lg-current, .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
  -webkit-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }
